import React from 'react';
import {
    Typography,
    InputLabel,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    makeStyles
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { changePasswordStyles as styles } from './styles';
import { passwordValidator } from '../../utils/helpers/index';

const ChangePasswordForm = ({
    newPassword,
    confirmNewPassword,
    onChangeNewPassword,
    onChangeConfirmNewPassword,
    onClickNewPassword,
    onClickConfirmNewPassword
}) => {
    const classes = makeStyles(styles)();

    return (
        <>
            <FormControl className={classes.margin1RemTop} variant="outlined" fullWidth>
                <InputLabel htmlFor="new-password-input">New password</InputLabel>
                <OutlinedInput
                    id="new-password-input"
                    value={newPassword.value}
                    onChange={onChangeNewPassword}
                    variant="outlined"
                    error={newPassword.value && !new RegExp(passwordValidator).test(newPassword.value)}
                    type={newPassword.showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={onClickNewPassword}>
                                {newPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControl className={classes.margin1RemTop} variant="outlined" fullWidth>
                <InputLabel htmlFor="confirm-password-input">Confirm new password</InputLabel>
                <OutlinedInput
                    id="confirm-password-input"
                    value={confirmNewPassword.value}
                    onChange={onChangeConfirmNewPassword}
                    variant="outlined"
                    type={confirmNewPassword.showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={onClickConfirmNewPassword}>
                                {confirmNewPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Typography className={classes.margin1RemTop}>
                It must contain at least 8 characters, one upper case and one number.
            </Typography>
        </>
    );
};

export default ChangePasswordForm;
