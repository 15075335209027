import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAuthenticated } from '../../redux/selectors/auth';
import { logout } from '../../redux/actions/auth';

/* Hook to initialize the websocket connection to logout customers when needed  */
export const useAutoLogout = () => {
    const dispatch = useDispatch();

    const authenticated = useSelector(isAuthenticated);
    const customerId = useSelector(state => state.auth.user.id);

    const [wsConnection, setWsConnection] = useState(undefined);

    try {
        if (authenticated) {
            const token = localStorage.getItem('token');

            // creates a websocket connection to keep track of possible changes, if there is no other connection created
            const ws = wsConnection || new WebSocket(JSON.parse(localStorage.getItem('linkCache')).websocketUrl + '/?token=' + token);

            if (!wsConnection) setWsConnection(ws);
            try {
                ws.onopen = () => {
                    ws.onmessage = event => {
                        try {
                            const data = JSON.parse(event.data);
                            if (data.from === 'logout-customer' && customerId === data.customerId) {
                                dispatch(logout());
                            }
                        } catch (err) {
                            console.error(err);
                            return;
                        }
                    };
                };
            } catch (error) {
                setWsConnection(undefined);
                console.error(error);
            }
        } else {
            // closes the existing socket connection
            if (wsConnection) {
                wsConnection.close();
                setWsConnection(undefined);
            }
        }
    } catch (error) {
        console.error(error);
    }
};
