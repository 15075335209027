export const FormViewStyles = {
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    formContentWrapper: {
        '& img.image-size-big': {
            maxWidth: '800px !important',
            maxHeight: '800px !important'
        }
    },
    formContent: {
        padding: '24px 16px 16px',
        width: 500,
        margin: '0 auto',
        '& .MuiGrid-item': {
            width: '100%'
        }
    },
    content: {
        minWidth: 300,
        maxWidth: 600,
        padding: 10
    },
    canvas: {
        border: '2px solid #95bcf2',
        minWidth: 300,
        maxWidth: 410,
        height: 90,
        padding: 5,
        overflow: 'hidden'
    },
    modalTitle: {
        textAlign: 'center',
        marginTop: 16
    },
    consent: {
        margin: '10px 0',
        textAlign: 'justify'
    },
    consentContainer: {
        margin: '10px 5px'
    },
    spacedTextContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    saveUserForm: {
        marginTop: 24
    },
    formButtonContainer: {
        width: '89vw',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 54,
        display: 'flex',
        justifyContent: 'center'
    },
    consentButtonContainer: {
        padding: '0px 5px 50px'
    }
};
