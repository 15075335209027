import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CircularProgress, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AditionalServiceDialog from './AditionalServiceDialog';
import Selector from '../../common/Selector';
import { fetchPractitioners } from '../../../api/practitionerApi';
import {
    changeStep,
    // changeCurrentAppointments,
    setCurrentCategory,
    changeService,
    updateAppointments,
    availableStaff
} from '../../../redux/actions/book-appointment';
import {
    getCurrentServices,
    getCurrentAppointments,
    getCurrentLocation
} from '../../../redux/selectors/book-appointment';
import { TIME, SERVICE } from '../../../constants/steps';
import { MOBILE } from '../../../constants/breakpoints';
// import { STAFF_IO_FROM_FRONT } from '../../../constants/socket';

import styles from './styles';
import { Info } from '@material-ui/icons';
import InfoModal from '../service/InfoModal';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import { getCurrencySymbol } from '../../../collums-components/helpers';
import { toastr } from 'react-redux-toastr';
import { getLocationItem } from '../../../collums-constants/utils';

function Staff({ classes }) {
    const [staff, setStaff] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [minValue, setMinValue] = useState(0);
    const history = useHistory();
    const { url } = useRouteMatch();

    const currentLocation = useSelector(getCurrentLocation);
    const currentServices = useSelector(getCurrentServices);
    const currentAppointments = useSelector(getCurrentAppointments);

    const [modalProps, setModalProps] = useState({
        title: '',
        content: '',
        isVisible: false
    });

    const dispatch = useDispatch();

    const handleDialogConfirm = () => {
        setDialogVisible(false);
        dispatch(changeStep(SERVICE));
        dispatch(setCurrentCategory(null));
        dispatch(changeService(null));
    };

    const handleDialogCancel = useCallback(() => {
        setDialogVisible(false);
        dispatch(changeStep(TIME));
        dispatch(setCurrentCategory(null));
        dispatch(changeService(null));
    }, [dispatch]);

    const openModal = data => {
        setModalProps({
            isVisible: true,
            title: data.title,
            content: data.content
        });
    };

    const handleInfoClick = (event, practitioner) => {
        event.stopPropagation();
        openModal({
            isVisible: true,
            content: practitioner.description,
            title: practitioner.displayName
        });
    };

    const handleStaffChange = useCallback(staff => {
        if (!currentAppointments) return;

        const missingStaff = currentAppointments.findIndex(current => current.staff === null);
        if (missingStaff === -1) {
            // dispatch(
            //     changeCurrentAppointments({
            //         service: currentServices,
            //         staff: staff
            //     })
            // );
        } else {
            const tempCurrAppts = currentAppointments;
            tempCurrAppts.splice(missingStaff, 1, {
                ...tempCurrAppts[missingStaff],
                service: {
                    ...tempCurrAppts[missingStaff].service,
                    defaultPrice: staff?.grossPrice || minValue,
                    ...(staff?.duration && {
                        defaultDuration: staff.duration
                    })
                },
                staff: staff
            });
            dispatch(updateAppointments(tempCurrAppts));
        }

        handleDialogCancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentServices, dispatch, minValue, history, url]); //currentAppointments

    useEffect(() => {
        const fetch = async () => {
            if (!currentServices || !currentLocation.id) return;

            if(currentServices?.locations){
                for (const loc of currentServices.locations){
                    if(!loc.clinic) loc.clinic = currentLocation.id;
                }
            }

            try {
                setIsLoading(true);

                const payload = {
                    month: getCurrentTimezonedDate().getMonth(),
                    clinic: currentLocation.id,
                    service: currentServices
                };
                const data = await fetchPractitioners(payload);

                dispatch(availableStaff(data));

                if (data.length === 1){
                    handleStaffChange(data[0]);
                }

                setStaff(data);
            } catch (err) {
                toastr.error(err?.data?.message || 'Something went wrong');

                return;
            } finally {
                setIsLoading(false);
            }
        };

        fetch();
    }, [currentLocation.id, currentServices, dispatch, handleStaffChange]);


    useEffect(() => {
        if (!staff || !staff.length) return;

        const values = staff.map(practitioner => practitioner.grossPrice);
        const minValue = Math.min(...values);

        setMinValue(minValue);
    }, [staff]);

    const durations = staff.map(practitioner => practitioner.duration);
    const minDuration = Math.min(...durations);
    const differentDurations = new Set(durations).size > 1;

    const haveCurrentServiceAndLocation = currentServices && currentLocation;
    const haveLocations = currentServices?.locations
        ? getLocationItem(currentServices, currentLocation.id)
        : currentServices?.location;
    const item = haveCurrentServiceAndLocation ? haveLocations : null;
    const defaultDuration = item?.defaultDuration || currentServices?.defaultDuration || 0;


    return (
        <Box className={classes.selectorContainer}>
            {!isLoading ? (
                <>
                    {((isMobile && !dialogVisible) || !isMobile) && (
                        <>
                            {staff.length ? (
                                <Selector className={['overflowVisible', classes.selector].join(' ')}>
                                    {staff.length > 1 && (
                                        <Selector.Row onClick={() => handleStaffChange(undefined)}>
                                            <Selector.Column>
                                                <Grid container justify="space-between">
                                                    <Grid item>
                                                        <Typography variant="h4">Any practitioner</Typography>
                                                        {currentServices?.showDurationOnline && (
                                                            <Typography className={classes.durationText}>
                                                                {differentDurations
                                                                    ? 'From ' + minDuration
                                                                    : (defaultDuration) ||
                                                                        0}{' '}
                                                                    mins
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        {currentServices?.showPriceOnline && (
                                                            <Grid item>
                                                                <Typography variant="h4">
                                                                    From: {getCurrencySymbol()}{parseFloat(minValue).toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Selector.Column>
                                        </Selector.Row>
                                    )}
                                    <Divider />
                                    {staff.map(practitioner => (
                                        <React.Fragment key={practitioner.id}>
                                            <Selector.Row
                                                onClick={() => handleStaffChange(practitioner)}
                                                className={classes.selectorColumn}
                                            >
                                                <Selector.Column>
                                                    <Grid container justify="space-between">
                                                        <Grid item>
                                                            <Typography variant="h4">
                                                                {practitioner.displayName}
                                                            </Typography>
                                                            {practitioner.description && (
                                                                <IconButton
                                                                    onClick={event =>
                                                                        handleInfoClick(event, practitioner)
                                                                    }
                                                                    className={classes.info}
                                                                >
                                                                    <Info />
                                                                </IconButton>
                                                            )}
                                                            {currentServices?.showDurationOnline && (
                                                                <Typography className={classes.durationText}>
                                                                    {practitioner.duration} mins
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid item>
                                                            {currentServices?.showPriceOnline && (
                                                                <Grid item>
                                                                    <Typography variant="h4">
                                                                        {getCurrencySymbol()}{parseFloat(practitioner.grossPrice || 0).toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Selector.Column>
                                            </Selector.Row>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </Selector>
                            ) : (
                                <Typography style={{ padding: 10, textAlign: 'center' }}>
                                    This service is currently unavailable
                                </Typography>
                            )}
                        </>
                    )}
                </>
            ) : (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            )}

            <AditionalServiceDialog
                open={dialogVisible}
                onConfirm={handleDialogConfirm}
                onCancel={handleDialogCancel}
            />
            <InfoModal
                setIsVisible={setModalProps}
                isVisible={modalProps.isVisible}
                title={modalProps.title}
                content={modalProps.content}
            />
        </Box>
    );
}

Staff.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Staff);
