import api from './api';

class CustomerApi {
    static async updateUser(customerId, data) {
        return api
            .request({
                method: 'PUT',
                url: `/customers/${customerId}`,
                data
            })
            .then(res => res)
            .catch(console.error);
    }

    static async updateUserDefaultCard(customerId, cardId) {
        return api
            .request({
                method: 'PUT',
                url: `/customers/default-card/${customerId}`,
                data: cardId
            })
            .then(res => res)
            .catch(err => err);
    }
}

export default CustomerApi;
