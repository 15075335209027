export const dropDown = () => ({
    paper: {
        border: '1px solid #d3d4d5'
    },
    menuItem: {
        root: {
            '&:focus': {
                backgroundColor: '#fff',
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: 'blue'
                }
            }
        }
    }
});
