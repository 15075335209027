import React, { useEffect } from 'react';
import { CreditCardInput, SquarePaymentsForm } from 'react-square-web-payments-sdk';
import PropTypes from 'prop-types';

const SquareCreditCardForm = ({ locationId, currentCustomer, sendForm, closeModal, isLoading, setLoading }) => {
    useEffect(() => {
        const button = document.getElementById('pay-with-card');

        if (button) {
            button.setAttribute('disabled', isLoading.toString());
        }
    }, [isLoading]);

    return (
        <div>
            {locationId ? (
                <SquarePaymentsForm
                    applicationId={process.env.REACT_APP_SQUARE_APP_ID}
                    cardTokenizeResponseReceived={async (token, buyer) => {
                        if (token && buyer) {
                            const data = {
                                nonce: token.token,
                                brand: token.details.card.brand,
                                cardNo: token.details.card.last4,
                                expDate: `${token.details.card.expMonth}/${token.details.card.expYear - 2000}`,
                                buyerVerificationToken: buyer.token,
                                billing_address: {
                                    postal_code: token.details.billing?.postalCode || currentCustomer.postCode
                                },
                                isRemovingCard: true,
                                isDefaultCard: false
                            };
                            setLoading(true);
                            await sendForm(data);
                        }
                    }}
                    createVerificationDetails={() => {
                        const button = document.getElementById('pay-with-card');

                        if (button) {
                            button.setAttribute('disabled', isLoading.toString());
                        }

                        const addressLines = currentCustomer?.address1 ? [currentCustomer.address1] : undefined;
                        return {
                            amount: '0',
                            billingContact: {
                                addressLines,
                                familyName: currentCustomer?.surname,
                                givenName: currentCustomer?.firstName,
                                countryCode: currentCustomer?.country?.value || 'GB',
                                city: currentCustomer?.city || 'London'
                            },
                            currencyCode: 'GBP',
                            intent: 'STORE'
                        };
                    }}
                    locationId={locationId}
                >
                    <CreditCardInput
                        scape={() => closeModal()}
                        text={'Save Card'}
                        overrideStyles={isLoading ? { backgroundColor: 'gray', cursor: 'not-allowed' } : {}}
                    />
                </SquarePaymentsForm>
            ) : (
                <></>
            )}
        </div>
    );
};

SquareCreditCardForm.propTypes = {
    locationId: PropTypes.string.isRequired,
    currentCustomer: PropTypes.object,
    sendForm: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    setLoading: PropTypes.func.isRequired
};

export default SquareCreditCardForm;
