import { MOBILE } from '../../../constants/breakpoints';

export default theme => ({
    selectorContainer: {
        padding: '1rem',
        width: '100%',
        '& hr:nth-last-child(1)': {
            display: 'none'
        },
        position: 'relative'
    },
    loading: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '1rem'
    },
    selector: {
        '&:hover': {
            '& div:nth-child(1)': {
                borderRadius: '10px 0 0 0'
            },
            '& div:nth-last-child(2)': {
                borderRadius: '0 0 0 10px'
            }
        },
        [theme.breakpoints.up(MOBILE)]: {
            height: '50vh',
            overflowY: 'scroll'
        }
    },
    durationText: {
        fontSize: '14px !important',
        color: '#000000DE',
        margin: '0.35rem 0 0'
    },
    actions: {
        display: 'none',
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center',
        marginTop: '2%',
        '& button': {
            marginRight: '2.5%',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.light
            }
        },
        [theme.breakpoints.up(MOBILE)]: {
            display: 'flex'
        }
    }
});

export const aditionalServiceDialogStyles = theme => ({
    button: {
        textTransform: 'none',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        margin: '.4rem'
    },
    text: {
        fontSize: '1rem',
        color: theme.palette.black.main,
        textAlign: 'center'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '50vh'
    },
    mobileActions: {
        textAlign: 'center'
    }
});
