import {
    APPOINTMENTS_FETCHED,
    ISFETCHINGAPPOINTMENTS,
    AUTH_USER,
    CHANGE_MODAL_VISIBILITY,
    CHANGE_USER_FORM_DATA,
    LOGOUT,
    UPDATE_USER,
    VALIDATE_USER_FORM,
    VALIDATE_IS_FORM_DIRTY,
    SET_ORGANISATION
} from '../actions';

const initialState = {
    authenticated: false,
    user: {},
    appointments: [],
    isUserFormDirty: false,
    isUserFormValid: true,
    userFormData: {},
    organisation: {},
    loginModalVisible: false,
    isFetchingAppointments: false
};

const reducer = (state = initialState, actions) => {
    switch (actions.type) {
        case AUTH_USER: {
            return { ...state, user: actions.payload, userFormData: actions.payload, authenticated: true };
        }
        case CHANGE_MODAL_VISIBILITY: {
            return { ...state, loginModalVisible: actions.payload };
        }
        case LOGOUT: {
            localStorage.removeItem('token');

            return {
                ...state,
                authenticated: false,
                user: {},
                loginModalVisible: false
            };
        }
        case UPDATE_USER: {
            return {
                ...state,
                user: actions.payload,
                isUserFormDirty: false
            };
        }
        case VALIDATE_USER_FORM: {
            return {
                ...state,
                isUserFormValid: actions.payload
            };
        }
        case VALIDATE_IS_FORM_DIRTY: {
            return {
                ...state,
                isUserFormDirty: actions.payload
            };
        }

        case APPOINTMENTS_FETCHED:
            return { ...state, appointments: actions.payload };

        case ISFETCHINGAPPOINTMENTS:
            return { ...state, isFetchingAppointments: actions.payload };

        case CHANGE_USER_FORM_DATA: {
            return {
                ...state,
                userFormData: actions.payload,
                isUserFormDirty: !!actions.payload
            };
        }

        case SET_ORGANISATION:
            return { ...state,organisation: actions.payload };     
        default: {
            return state;
        }
    }
};

export default reducer;
