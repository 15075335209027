import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    DialogActions,
    makeStyles
} from '@material-ui/core';

import { setCurrentTimeStep, setCurrentDate, chooseTime } from '../../../redux/actions/book-appointment';

import { timeout as timeoutStyle } from './styles';

const Timeout = ({ time }) => {
    const dispatch = useDispatch();
    const [active, setActive] = useState(false);
    const classes = makeStyles(theme => timeoutStyle(theme))();

    const handleClose = useCallback(() => {
        dispatch(setCurrentTimeStep(null));
        dispatch(setCurrentDate(null));
        dispatch(chooseTime(null));
        setActive(!active);
    }, [active, dispatch]);

    useEffect(() => {
        setActive(!time);
    }, [time, setActive]);

    return (
        <Dialog
            open={active}
            maxWidth={'xs'}
            className={classes.dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={classes.title} id="alert-dialog-title">
                Out of time
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.description} id="alert-dialog-description">
                    We can only hold your appointment time for 5 minutes. Please choose your time again and complete
                    your booking within 5 minutes
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} className={classes.btn} color="primary" autoFocus>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Timeout;
