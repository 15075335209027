import * as actions from '.';
import ServicesApi from '../../api/servicesApi';
import { LOCATION, STAFF, TIME } from '../../constants/steps';

export const changeStep = step => dispatch => {
    return dispatch({
        type: actions.CHANGE_STEP,
        payload: step
    });
};

export const clearBookingInfo = () => dispatch => {
    return dispatch({
        type: actions.CLEAR_BOOKING_INFO
    });
};

export const chooseTime = time => dispatch => {
    return dispatch({
        type: actions.CHOOSE_TIME,
        payload: time
    });
};

export const changeLocation = location => dispatch => {
    return dispatch({
        type: actions.CHANGE_LOCATION,
        payload: {
            ...location
        }
    });
};

export const setCurrentDate = payload => dispatch => {
    return dispatch({
        type: actions.SET_CURRENT_DATE,
        payload
    });
};

export const addService = service => dispatch => {
    return dispatch({
        type: actions.ADD_SERVICE,
        payload: service
    });
};

export const changeService = service => dispatch => {
    return dispatch({
        type: actions.CHANGE_SERVICE,
        payload: service
    });
};

export const updateAppointments = appts => dispatch => {
    return dispatch({
        type: actions.UPDATE_APPOINTMENTS,
        payload: appts
    });
};

export const clearLocation = () => dispatch => {
    return dispatch({
        type: actions.CLEAR_LOCATION,
        payload: {
            currentAppointments: [],
            currentLocation: null,
            currentStep: LOCATION,
            currentCategory: null
        }
    });
};

export const fetchTime = (data, setIsLoading) => async dispatch => {
    setIsLoading(true);
    const hours = [];
    await Promise.all(data.map(async appt => {
        hours.push(await ServicesApi.getAvailableTime({ ...appt, onlineBooking: true }));
    }));
    setIsLoading(false);

    return dispatch({
        type: actions.TIMES_FETCHED,
        payload: hours
    });
};

export const appointmentFromManageAppointment = data => dispatch => {
    const payload = {
        appointmentId: data.id,
        currentStep: data.linkedAppointments.length || data.isLinked ? TIME : STAFF,
        currentLocation: {
            ...data.clinic
        },
        currentServices: {
            ...data.service
        },
        currentAppointments: [
            {
                appointmentId: data.id,
                service: { ...data.service },
                staff: data.linkedAppointments.length || data.isLinked ? { ...data.practitioner } : null,
                event: data.event,
                isLinked: data.isLinked,
                linkedAppointments: data.linkedAppointments
            },
            ...data.appointments
        ],
        currentTimeStep: null,
        isBookAgain: data.isBookAgain,
        isReschedule: data.isReschedule,
        isLinked: data.isLinked,
        linkedAppointments: data.linkedAppointments
    };

    return dispatch({
        type: actions.RESCHEDULE_APPOINTMENT,
        payload
    });
};

export const changeStaff = staff => dispatch =>
    dispatch({
        type: actions.CHANGE_STAFF,
        payload: staff
    });

export const availableStaff = data => dispatch => dispatch({
    type: actions.AVAILABLE_STAFF,
    payload: data
});

export const setInfo = payload => dispatch =>
    dispatch({
        type: actions.INFO,
        payload
    });

export const setCurrentCategory = payload => dispatch =>
    dispatch({
        type: actions.SET_CURRENT_CATEGORY,
        payload
    });

export const setCurrentTimeStep = payload => dispatch =>
    dispatch({
        type: actions.SET_CURRENT_TIME_STEP,
        payload
    });

export const setCurrentConfirmStep = payload => dispatch =>
    dispatch({
        type: actions.SET_CURRENT_CONFIRM_STEP,
        payload
    });

export const changeCurrentAppointments = payload => dispatch =>
    dispatch({
        type: actions.CHANGE_CURRENT_APPTS,
        payload
    });

export const setInitialState = payload => dispatch => {
    dispatch({
        type: actions.SET_INITIAL_STATE,
        payload
    });
};

export const overrideAppointment = payload => dispatch => {
    dispatch({
        type: actions.OVERRIDE_APPOINTMENT,
        payload
    });
};
