import { createMuiTheme } from '@material-ui/core';
import { MOBILE, TABLET, DESKTOP } from '../constants/breakpoints';
const theme = createMuiTheme({
    shadows: ['none'],
    app: 'customer-app',
    formWidth: '89vw',
    formMargin: '16px auto 0',
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'mobile', 'tablet', 'desktop'],
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            mobile: MOBILE,
            tablet: TABLET,
            desktop: DESKTOP
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true
        }
    },
    palette: {
        primary: {
            light: '#4496FC',
            main: '#3083EC'
        },
        secondary: {
            main: '#69767E'
        },
        white: {
            main: '#ffffff'
        },
        black: {
            main: '#343232',
            full: '#000000'
        },
        pink: {
            main: '#ff00ff'
        },
        blue: {
            main: '#2b78e4',
            darken: '#266ac9'
        },
        red: {
            main: '#FB4336'
        },
        gray: {
            main: '#69767E',
            bold: '#565A65',
            light: '#cccccc',
            lighter: '#eeeeee',
            border: 'rgba(0, 0, 0, 0.23)'
        },
        wine: {
            main: '#b41778',
            hover: '#912d6b'
        },
        event: {
            primary: '#3c3c3d',
            secondary: '#9d9d9f',
            checkedIn: '#3da768',
            checkedOut: '#2d3548',
            late: '#ef4842',
            missed: '#8a211f',
            block: '#ffffff',
            break: '#aadcf3'
        },
        appointment: '#32384b',
        break: '#aadcf3',
        block: '#6bcbd9'
    },
    typography: {
        fontFamily: ['Roboto', 'Arial', 'sans-serif'],
        fontSize: 14,
        h6: {
            fontSize: '0.67rem',
            fontFamily: 'Roboto, Arial, sans-serif'
        },
        h5: {
            fontSize: '0.83rem',
            fontFamily: 'Roboto, Arial, sans-serif'
        },
        h4: {
            fontSize: '1rem',
            fontFamily: 'Roboto, Arial, sans-serif'
        },
        h3: {
            fontSize: '1.17rem',
            fontFamily: 'Roboto, Arial, sans-serif'
        },
        h2: {
            fontSize: '1.5rem',
            fontFamily: 'Roboto, Arial, sans-serif'
        },
        h1: {
            fontSize: '2rem',
            fontFamily: 'Roboto, Arial, sans-serif'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: 14,
                textTransform: 'none'
            }
        },
        MuiOutlinedInput: {
            root: {
                height: 38,
                lineHeight: '10px'
            }
        },
        MuiInputLabel: {
            root: {
                lineHeight: '1px',
                color: '#333'
            }
        },
        MuiSelect: {
            root: {
                fontSize: 12,
                fontFamily: 'Roboto, Arial, sans-serif'
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 12
            }
        },
        MuiTab: {
            root: {
                fontSize: 14,
                textTransform: 'none'
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: 24
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 10
            }
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiAutocomplete: {
            inputRoot: {
                fontSize: 12,
                fontFamily: 'Roboto, Arial, sans-serif',
                height: 'auto'
            },
            option: {
                fontSize: 12,
                fontFamily: 'Roboto, Arial, sans-serif'
            }
        },
        MuiTableCell: {
            head: {
                fontFamily: 'Roboto, Arial, sans-serif'
            }
        }
    },
    zIndex: {
        spinner: 2000,
        appBar: 1201,
        // defaults
        mobileStepper: 1000,
        speedDial: 1050,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500
    }
});
export default theme;
