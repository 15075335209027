import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';

import { GENDERS, TITLES } from '../../../collums-constants/index';
import SimpleCountriesInput from '../../../collums-components/form/SimpleCountriesInput';

import { personalDetailsStyles } from './styles';

import {
    Button,
    Checkbox,
    FormControlLabel,
    MenuItem,
    TextField,
    withStyles,
    Typography,
    FormControl
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import PhoneNumberInput from 'material-ui-phone-number';
import { isValidPhone } from '../../../collums-components/helpers/validPhoneNumber';
import { changeUserFormData, updateUser, validateUserForm } from '../../../redux/actions/auth';
import { getOrganisationCountryCode } from '../../../collums-components/helpers';
import Moment from 'moment/moment';

function RenderRequiredLabel({ label }) {
    return (
        <>
            <p>
                {label}
                <span style={{ color: 'red', paddingLeft: 2 }}>*</span>
            </p>
        </>
    );
}

function PersonalDetails({ classes }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.userFormData);
    const authUser = useSelector(state => state.auth.user);
    const organisation = useSelector(state => state.auth.organisation);
    const [formData, setFormData] = useState({});
    const [isInvalid, setIsInvalid] = useState({
        phone: false
    });

    useEffect(() => {
        if (authUser) {
            const data = { ...formData };

            data.id = authUser?.id;
            data.title = authUser?.title || '';
            data.firstName = authUser?.firstName || '';
            data.surname = authUser?.surname || '';
            data.gender = authUser?.gender || '';
            data.middleName = authUser?.middleName;
            data.dateOfBirth = authUser?.dateOfBirth || '';
            data.address1 = authUser?.address1 || '';
            data.address2 = authUser?.address2;
            data.county = authUser?.county;
            data.city = authUser?.city || '';
            data.postCode = authUser?.postCode || '';
            data.country = authUser?.country;
            data.marketingTypes = authUser?.marketingTypes || [];
            data.notificationTypes = authUser?.notificationTypes || [];
            data.mobilePhone = authUser?.mobilePhone || '';

            setFormData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);

    const handleChange = (key, value) => {
        const isNestedItem = key.split('.');

        const newFormData = () => {
            if (['Email', 'SMS'].includes(key)) {
                if (!value) {
                    return { marketingTypes: formData.marketingTypes?.filter(item => item !== key) };
                }
                return { marketingTypes: [formData?.marketingTypes, key] };
            }

            if (key === 'email') {
                return {
                    email: {
                        ...formData?.email,
                        address: value
                    }
                };
            }

            if (isNestedItem.length > 1) {
                const item = isNestedItem[0];
                const property = isNestedItem[1];
                const isArray = key.indexOf('[');

                if (isArray !== -1) {
                    const itemWithoutIndex = item.split('[')[0];
                    const index = key.replace(/[^0-9]/g, '');
                    const arrayValue = formData[itemWithoutIndex][index];
                    const newArrayValue = { ...arrayValue, [property]: value };

                    return {
                        [itemWithoutIndex]: [newArrayValue]
                    };
                }

                return {
                    [item]: {
                        ...formData[item],
                        [property]: value
                    }
                };
            }

            return {
                [key]: value
            };
        };
        dispatch(changeUserFormData({ ...formData, ...newFormData() }));
        setFormData({ ...formData, ...newFormData() });
    };

    const handleCheckboxChange = (key, value, type) => {
        const newFormData = () => {
            if (type === 'marketingTypes') {
                if (value) {
                    return { marketingTypes: [...(formData?.marketingTypes || []), key] };
                }
                return { marketingTypes: (formData.marketingTypes || [])?.filter(item => item !== key) };
            }

            if (type === 'notificationTypes') {
                if (value) {
                    return { notificationTypes: [...(formData?.notificationTypes || []), key] };
                }
                return { notificationTypes: (formData.notificationTypes || [])?.filter(item => item !== key) };
            }
        };
        dispatch(changeUserFormData({ ...formData, ...newFormData() }));
        setFormData({ ...formData, ...newFormData() });
    };

    const validateForm = () => {
        if (!formData.country) {
            formData.country = {
                value: 'GB',
                label: 'United Kingdom'
            };
        }
        const requiredFields = [
            { field: 'title', label: 'Title' },
            { field: 'firstName', label: 'First Name' },
            { field: 'surname', label: 'Surname' },
            { field: 'gender', label: 'Gender' },
            { field: 'dateOfBirth', label: 'Date of Birth' },
            { field: 'address1', label: 'Address line 1' },
            { field: 'city', label: 'City' },
            { field: 'postCode', label: 'Postcode' },
            { field: 'country', label: 'Country' },
            { field: 'mobilePhone', label: 'Mobile Phone' }
        ];

        const missingFields = requiredFields.filter(field => !formData[field.field]);
        if (!missingFields.length) return { valid: true, items: [] };
        else return { valid: false, items: missingFields };
    };

    const handleUpdateUser = async () => {
        const isValid = validateForm();
        if (!isValid.valid) {
            toastr.error('The following field is required:', isValid.items[0].label);
            return;
        }

        if (!isValidPhone(formData.mobilePhone)) {
            toastr.error('Need to add a valid phone number');
            return;
        }
        const customer = {
            ...formData,
            email: formData.email?.address,
            dateOfBirth: Moment(formData.dateOfBirth).format('YYYY-MM-DD') + 'T12:00:00.00Z'
        };

        dispatch(updateUser({ id: user?.id, customer }));
    };

    useEffect(() => {
        const formValidity = validateForm();
        dispatch(validateUserForm(formValidity.valid));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    const getCountryCode = () => {
        return getOrganisationCountryCode({
            ...organisation,
            address: {
                country: organisation?.country || undefined
            }
        });
    };

    return (
        <>
            <div className={classes.personalDetailsRoot}>
                <div className={classes.personalDetailsSection}>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <RenderRequiredLabel label="Title"/>
                        <TextField
                            select
                            value={formData.title ? formData.title.toString() : ''}
                            onChange={e => {
                                handleChange('title', e.target.value);
                                setTimeout(() => {
                                    document.activeElement.blur();
                                }, 0);
                            }}
                            variant="outlined"
                            placeholder="Title"
                        >
                            {Object.values(TITLES)
                                .filter(title => title !== TITLES.CUSTOM)
                                .map(title => (
                                    <MenuItem value={title} key={title}>
                                        {title}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <RenderRequiredLabel label="First Name"/>
                        <TextField
                            variant="outlined"
                            value={formData?.firstName}
                            onChange={e => handleChange('firstName', e.target.value)}
                            placeholder="First Name"
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <p>Middle Name</p>
                        <TextField
                            variant="outlined"
                            value={formData?.middleName}
                            onChange={e => handleChange('middleName', e.target.value)}
                            placeholder="Middle Name"
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <RenderRequiredLabel label="Surname"/>
                        <TextField
                            variant="outlined"
                            value={formData?.surname}
                            onChange={e => handleChange('surname', e.target.value)}
                            placeholder="Surname"
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <RenderRequiredLabel label="Gender"/>
                        <TextField
                            select
                            value={formData.gender ? formData.gender.toString() : ''}
                            onChange={e => {
                                handleChange('gender', e.target.value);
                                setTimeout(() => {
                                    document.activeElement.blur();
                                }, 0);
                            }}
                            placeholder="Gender"
                            variant="outlined"
                        >
                            {Object.values(GENDERS)
                                .filter(gen => gen !== 'Custom')
                                .map(gen => (
                                    <MenuItem key={gen} value={gen}>
                                        <Typography>{gen}</Typography>
                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <RenderRequiredLabel label="Dob"/>
                        <KeyboardDatePicker
                            inputVariant="outlined"
                            value={formData?.dateOfBirth || null}
                            format="dd/MM/yyyy"
                            placeholder="dd/mm/yyyy"
                            onChange={e => handleChange('dateOfBirth', e)}
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot} ${classes.inputSection}`}>
                        <RenderRequiredLabel label="Address 1"/>
                        <TextField
                            variant="outlined"
                            value={formData?.address1}
                            onChange={e => handleChange('address1', e.target.value)}
                            placeholder={'Enter your address line 1*'}
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <p>Address 2</p>
                        <TextField
                            variant="outlined"
                            value={formData?.address2}
                            onChange={e => handleChange('address2', e.target.value)}
                            placeholder={'Enter your address line 2'}
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <RenderRequiredLabel label="City"/>
                        <TextField
                            variant="outlined"
                            value={formData?.city}
                            onChange={e => handleChange('city', e.target.value)}
                            placeholder={'Enter your city*'}
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <p>County</p>
                        <TextField
                            variant="outlined"
                            value={formData?.county}
                            onChange={e => handleChange('county', e.target.value)}
                            placeholder={'Enter your county'}
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <RenderRequiredLabel label="Postcode"/>
                        <TextField
                            variant="outlined"
                            value={formData?.postCode}
                            onChange={e => handleChange('postCode', e.target.value)}
                            placeholder={'Enter your postcode*'}
                        />
                    </div>
                    <div className={`${classes.inputRoot}`}>
                        <p>Country</p>
                        <FormControl style={{ marginTop: 8, width: '65%' }}>
                            <SimpleCountriesInput
                                name={'country'}
                                value={formData.country || { value: 'GB', label: 'United Kingdom' }}
                                onChange={value => handleChange('country', value)}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={classes.personalDetailsSection}>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <RenderRequiredLabel label={'Mobile Phone'}/>
                        <PhoneNumberInput
                            variant="outlined"
                            value={formData?.mobilePhone}
                            enableLongNumbers={true}
                            InputProps={{
                                className: classes.inputPhoneNumber
                            }}
                            error={isInvalid.phone}
                            inputProps={{
                                className: classes.inputPhoneNumber,
                                maxLength: 20
                            }}
                            onChange={(value, phoneInfo) => {
                                setIsInvalid({
                                    ...isInvalid,
                                    phone: !isValidPhone(value, phoneInfo.countryCode, phoneInfo.dialCode)
                                });
                                handleChange('mobilePhone', value);
                            }}
                            preferredCountries={['gb', 'fr', 'it', 'es', 'ie']}
                            defaultCountry={getCountryCode()}
                        />
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot} ${classes.inputSection}`}>
                        <p>Email</p>
                        <p>{typeof formData.email === 'object' ? formData.email.address : formData.email || ''}</p>
                    </div>
                    <div className={`${classes.inputRoot} ${classes.textRoot}`}>
                        <p>Receive offers</p>
                        <div className={classes.marketing}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            formData.marketingTypes ? formData.marketingTypes.includes('Email') : false
                                        }
                                        onChange={() =>
                                            handleCheckboxChange(
                                                'Email',
                                                !formData.marketingTypes?.includes('Email'),
                                                'marketingTypes'
                                            )
                                        }
                                        color={'primary'}
                                    />
                                }
                                label={'Email'}
                                labelPlacement={'end'}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            formData.marketingTypes ? formData.marketingTypes.includes('SMS') : false
                                        }
                                        onChange={() =>
                                            handleCheckboxChange(
                                                'SMS',
                                                !formData.marketingTypes?.includes('SMS'),
                                                'marketingTypes'
                                            )
                                        }
                                        color={'primary'}
                                    />
                                }
                                label={'SMS'}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                    <div
                        className={`${classes.inputRoot} ${classes.creditCardSectionRoot} ${classes.inputSection}`}></div>
                </div>
            </div>
            <div className={classes.actions}>
                <Button variant="contained" onClick={() => handleUpdateUser()}>
                    Save changes
                </Button>
            </div>
        </>
    );
}

PersonalDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(personalDetailsStyles)(PersonalDetails);
