import React from 'react';

import { withStyles, Button, Menu, MenuItem, ListItemText } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import { dropDown } from './styles';

/*
	submenu is an array of objects with the menus of 
	dropdowns containing label(string) and action(function)
*/
const DropMenu = ({ label, submenu, isMobile, classes }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const renderMenu = () =>
        (submenu || []).map((el, index) => (
            <MenuItem key={index} className={classes.menuItem} onClick={() => el.action()}>
                <ListItemText primary={el.label} />
            </MenuItem>
        ));

    return (
        <div>
            {!isMobile ? (
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                >
                    {label}
                </Button>
            ) : (
                <EventIcon color="primary" onClick={handleClick} />
            )}
            <Menu
                id="customized-menu"
                className={classes.paper}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {renderMenu()}
            </Menu>
        </div>
    );
};

export default withStyles(dropDown)(DropMenu);
