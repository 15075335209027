import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { bannersStyles } from './style';

export default function CookieBanner() {
    const classes = makeStyles(bannersStyles)();

    const [cookieBanner, setcookieBanner] = useState(true);

    const handleCookieBanner = () => {
        localStorage.setItem('cookieBanner', 'accept');
        setcookieBanner(false);
    };

    return (
        <div>
            {cookieBanner && (
                <div className={classes.cookieBanner}>
                    This website uses cookies to analyze user behavior and improve our services.
                    <Button className={classes.button} variant="outlined" color="primary" onClick={handleCookieBanner}>
                        Got it!
                    </Button>
                </div>
            )}
        </div>
    );
}
