import { Box, Button, Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { loginModalStyles } from './styles';

const InfoModal = ({ onClose, openInfoModal, infoText }) => {
    const classes = makeStyles(loginModalStyles)();
    return (
        <Dialog open={openInfoModal} className={`${classes.container} ${infoText === 'sign_up_existing_customer_email' ? classes.containerSmallPaddings : ''}`}>
            <DialogContent>
                {infoText === 'sign_up_existing_customer_email' &&
                <>
                    <Typography className={classes.accountCreated}>
                        A reset password link has been sent to this email (if it exists on our system).
                    </Typography>
                    <Typography className={classes.accountCreated}>
                        If you do not receive an email, check your junk box. If not there, try again using the email present in our system.
                    </Typography>
                    <Typography className={classes.accountCreated}>
                        <b>If you still do not receive an email, sign up as a new client and let the clinic know.</b>
                    </Typography>
                    <Typography className={classes.accountCreated}>
                        If you were in the process of making a booking, once you have verified your email, please return to that screen to complete the booking.
                    </Typography>
                </>}
                {infoText === 'sign_up_new_customer_email' &&
                    <Typography className={classes.accountCreated}>
                        Your account has been created. Please check your inbox (and junk mail) to verify your email before logging in.<br />
                        If you were in the process of making a booking, once you have verified your email, please return to that screen to complete the booking.
                    </Typography>
                }
                <Box display="flex" justifyContent={'center'} className={classes.buttonsContainer}>
                    <Button

                        onClick={onClose}
                        className={[classes.button, classes.blueButton]}
                    >
                        Close
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default InfoModal;