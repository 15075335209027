import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, makeStyles } from '@material-ui/core';

import AddressApi from '../api/AddressApi';

import { tagsStyles } from './styles';
import { isArray } from 'lodash';

function SimpleCountriesInput({ name, value, size, onChange, className, required = false }) {
    const [options, setOptions] = useState([]);

    const classes = makeStyles(tagsStyles)();

    const label = 'Country' + (required ? ' *' : '');

    const currentClinic = localStorage.getItem('currentClinic');

    async function getCountriesList() {
        const res = await AddressApi.getCountries(currentClinic);
        if (res && isArray(res)) {
            const validRes = res.filter(item => {
                return !!item;
            });
            setOptions(validRes);
        }
    }

    useEffect(() => {
        getCountriesList();
        /* eslint-disable-next-line */
    }, []);

    return (
        <div className={classes.root}>
            <Autocomplete
                className={className}
                size={size || 'small'}
                value={value}
                fullWidth
                name={name}
                autoComplete
                autoHighlight
                options={options}
                getOptionLabel={opt => opt.label}
                onChange={(e, value) => {
                    onChange(value);
                }}
                renderInput={params => <TextField {...params} variant="outlined" fullWidth label={label} />}
            />
        </div>
    );
}

SimpleCountriesInput.propTypes = {
    name: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    className: PropTypes.object
};

export default SimpleCountriesInput;
