import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordStyles as styles } from './styles';

import { check_email, insert_password, reset_password_email_sent } from '../../constants/messages';

import { Box, Typography, TextField, Button, makeStyles, DialogTitle, Dialog, DialogContent } from '@material-ui/core';

import ChangePasswordForm from '../common/ChangePasswordForm';

// import { authUser } from '../../redux/actions/auth';

import api from '../../api/authApi';
import { clearBookingInfo } from '../../redux/actions/book-appointment';
import { toastr } from 'react-redux-toastr';
// import { toastr } from 'react-redux-toastr';

function ForgotPassword() {
    const { token } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [newPassword, setNewPassword] = useState({
        value: '',
        showPassword: false
    });
    const [confirmNewPassword, setConfirmNewPassword] = useState({
        value: '',
        showPassword: false
    });
    const organisation = useSelector(state => state.auth.organisation);
    const [displayInfo, setDisplayInfo] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [email, setEmail] = useState('');
    const url = window.location.pathname.split('/');
    const classes = makeStyles(styles)();
    const title = (() => {
        if (isResetPassword) return insert_password;
        return check_email(organisation.name);
    })();

    useEffect(() => {
        if (!url.includes('reset-password')) return;
        setIsResetPassword(true);
    }, [url]);

    async function handleClick() {
        if (isResetPassword) {
            if (newPassword.value !== confirmNewPassword.value) {
                setDisplayInfo(true);
                return;
            }

            const { newToken } = await api.changePassword({ token, newPassword: newPassword.value });
            localStorage.setItem('token', newToken);
            dispatch(clearBookingInfo());
            setIsDialogOpen(true);
            return;
        }

        if (!email.length) {
            toastr.error('You must enter an email address.');
            return;
        }
        try {
            const response = await api.forgotPassword(email.toLowerCase());
            if (response.error) {
                throw new Error(response.message);
            }
            setIsDialogOpen(true);
        } catch (e) {
            setDisplayInfo(true);
            console.error(e);
            // toastr.error('Error', e.message || 'Something went wrong');
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                <Box className={classes.logoBox}>
                    <DialogTitle className={classes.title}>Reset Password Request</DialogTitle>
                </Box>
                <Typography style={{ textAlign: 'justify' }}>{title}</Typography>
                <Box>
                    {isResetPassword ? (
                        <ChangePasswordForm
                            newPassword={newPassword}
                            confirmNewPassword={confirmNewPassword}
                            onClickNewPassword={() =>
                                setNewPassword({ ...newPassword, showPassword: !newPassword.showPassword })
                            }
                            onClickConfirmNewPassword={() =>
                                setConfirmNewPassword({
                                    ...confirmNewPassword,
                                    showPassword: !confirmNewPassword.showPassword
                                })
                            }
                            onChangeNewPassword={event => setNewPassword({ ...newPassword, value: event.target.value })}
                            onChangeConfirmNewPassword={event =>
                                setConfirmNewPassword({ ...confirmNewPassword, value: event.target.value })
                            }
                        />
                    ) : (
                        <TextField
                            className={classes.margin1RemTop}
                            fullWidth
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            variant="outlined"
                            label="Email"
                            type="email"
                            required
                        />
                    )}
                    {displayInfo && (
                        <Typography className={`${classes.info} ${isResetPassword ? classes.warning : ''}` }>
                            {isResetPassword ? 'Passwords do not match' : reset_password_email_sent}
                        </Typography>
                    )}
                    <Button className={[classes.margin1RemTop, classes.button]} onClick={handleClick}>
                        Reset password
                    </Button>
                </Box>
            </Box>
            <Dialog open={isDialogOpen}
                className={classes.container}>
                <DialogTitle className={classes.title}>{isResetPassword ? '' : 'Your link has been emailed'}</DialogTitle>
                <DialogContent>
                    {isResetPassword ? 'Your new password has been created.' : 'If you do not receive it, check that you have entered the correct email. Please also check your junk folder.'}
                    <Box display="flex" justifyContent="center" className={classes.buttonsContainer}>
                        <Button className={[classes.button]} onClick={() => {
                            setIsDialogOpen(false);
                            history.push('/');}}>
                            Continue
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default ForgotPassword;
