import { getCurrencySymbol } from '../collums-components/helpers';

export const check_email = name => {
    return `Please enter the email that ${name} uses to contact you and click "Reset password". An email containing a reset password link will be sent to you.`;
};
export const complete_fields = 'Enter your information to create a new account';
export const insert_password = 'Please enter your new password.';
export const late_cancellation = (cancellation_period, cancellation_charge,cancellation_type) =>{
    return `Changes to this appointment are within ${Math.round(cancellation_period) || 48} hours which will result in a ${cancellation_type === 'AMOUNT' ? getCurrencySymbol() : ''}${
        cancellation_charge || 0
    }${cancellation_type === 'PERCENTAGE' ? '%' : ''} charge to your card or, in the case of a treatment that is part of a course, forfeiting the treatment. Are you sure you want to continue?`;
};
export const reset_password_email_sent =
    "If this email exists on our system, you will receive a password link - please also check your junk folder. If the link doesn't work, copy and paste it into your browser.";
export const existing_customer_email =
    "If this email exists on our system, you will receive a password link - please also check your junk folder. If the link doesn't work, copy and paste it into your browser.";
export const sign_up_existing_customer_email =
    'A reset password link has been sent to this email (if it exists on our system)\n If you do not receive an email, please first check your junk folder and then ensure that you are using the email that you registered with. / If you still do not receive an email, please contact the clinic.';
export const sign_up_new_customer_email =
    'Your account has been created. Please check your inbox (and junk mail) to verify your email before logging in.';
    
export const confirm_appointment_cancellation = 'Are you sure you want to cancel this appointment?';
