import React from 'react';
import { Typography, Box } from '@material-ui/core';

function StoredCreditCard({ card }) {
    if (!card) return null;

    const cardInfo = (label, value) => {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems={'start'}>
                <Typography>{label}</Typography>
                <Typography>{value || ''}</Typography>
            </Box>
        );
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxWidth="680px"
            minWidth="380px"
            width="fit-content"
            margin="auto"
        >
            {cardInfo('CardNo', `**** **** **** ${card.last_4}`)}
            {cardInfo('Brand', card.card_brand)}
            {cardInfo('Exp Date', `${card.exp_month}/${card.exp_year}`)}
            {cardInfo('CVV', '***')}
        </Box>
    );
}

export default StoredCreditCard;
