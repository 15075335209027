import api from './config';

export function listForms(skip = 0, limit = 15, queryParams = '') {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'GET',
        url: `/forms?skip=${skip}&limit=${limit}${queryParams ? `&${queryParams}` : ''}`
    });
}

export function getForm(id) {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'GET',
        url: `/forms/${id}`
    });
}

export function createForm(data) {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'POST',
        url: '/forms',
        data
    });
}

export function updateForm(id, data) {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'PUT',
        url: `/forms/${id}`,
        data
    });
}

export function updateFormActive(id) {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'PUT',
        url: `/forms/${id}/change-active`
    });
}

export function archiveForm(id) {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'DELETE',
        url: `/forms/${id}`
    });
}

export function getJourneyForms(id) {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'GET',
        url: `/forms/journey/${id}`
    });
}

export function getJourneyMedicalForms(id) {
    return api.request({
        method: 'GET',
        url: `/forms/journey/${id}/medical`
    });
}

export function getFormsByCustomer(id, query) {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'GET',
        url: `/forms/customer/${id}?${query}`
    });
}

export function getAllFormsByType(query) {
    if (api.params.headers.Authorization === null) {
        api.resetToken(localStorage.getItem('token'));
    }
    return api.request({
        method: 'GET',
        url: `/forms/all?${query}`
    });
}

export function createUserForms(customerId, data) {
    return api.request({
        method: 'POST',
        url: `/forms/user-form/${customerId}`,
        data
    });
}

export function getCustomerForm(formId, password) {
    return api.request({
        method: 'GET',
        url: `/forms/user-form/${formId}${password ? `?password=${password}` : ''}`
    });
}

export function signUserConsent(formId, data) {
    return api.request({
        method: 'POST',
        url: `/forms/user-form/sign/${formId}`,
        data
    });
}

export function updateUserForm(formId, data) {
    return api.request({
        method: 'POST',
        url: `/forms/user-form/update/${formId}`,
        data
    });
}

export function getFormLog(formId, journeyId) {
    return api.request({
        method: 'GET',
        url: `/forms/logs/${formId}?${journeyId ? `journeyId=${journeyId}` : ''}`
    });
}

export function getAvailableForServices() {
    return api.request({
        method: 'GET',
        url: '/forms/get-for-services'
    });
}

export function getFormToEdit(data) {
    return api.request({
        method: 'GET',
        url: `/forms/edit/${data.formId}?from=${data.from}&sectionId=${data.sectionId}`
    });
}

export function signUserConsentPublic(formId, data) {
    return api.request({
        method: 'POST',
        url: `/forms/user-form/sign/${formId}/public`,
        data
    });
}

export function updateUserFormPublic(formId, data) {
    return api.request({
        method: 'POST',
        url: `/forms/user-form/update/${formId}/public`,
        data
    });
}
export function sendConsentEmail({ customer, consentUrl }) {
    return api.request({
        method: 'POST',
        url: '/forms/user-form/email',
        data: { customer, consentUrl }
    });
}
export function sendConsentEmailForm({ customer, base64, userFormId }) {
    return api.request({
        method: 'POST',
        url: '/forms/user-form/email',
        data: { customer, base64, userFormId }
    });
}
