export const bannersStyles = () => ({
    stagingBanner: {
        background: '#f7a336',
        position: 'absolute',
        bottom: 0,
        color: 'white',
        textAlign: 'center',
        padding: '0px 8px 0px 8px',
        fontSize: '12px',
        width: '100%'
    },
    cookieBanner: {
        color: 'white',
        fontSize: '12px',
        width: '100%',
        backgroundColor: '#3083EC',
        textAlign: 'center',
        bottom: '0',
        top: 'auto',
        position: 'absolute'
    },
    button: {
        borderWidth: '1px',
        borderColor: 'white',
        color: 'white',
        margin: '5px 20px',
        padding: '0',
        fontSize: '12px',

        '&:hover': {
            borderColor: 'white'
        }
    }
});
