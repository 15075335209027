import * as actions from '../actions';

const initialState = {
    cardNo: '',
    brand: '',
    expDate: '',
    cvv: '',
    isDefaultCard: false,
    postCode: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.INFO:
            return { ...action.payload };

        default:
            return state;
    }
};

export default reducer;
