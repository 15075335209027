import Axios from 'axios';
import querystring from 'querystring';
import _ from 'lodash';

import { links } from '../collums-components/api/config'

const defaultParams = {
    baseURL: links.backendUrl,
    paramsSerializer: params => {
        const paramsObject = _.pickBy(params, value => null != value);
        return _.size(paramsObject) ? querystring.stringify(paramsObject) : '';
    }
};

class Api {
    constructor() {
        this.params = defaultParams;
    }

    config(params) {
        this.params = { ...this.params, ...params };
    }

    async request(params) {
        try {
            const response = await Axios({ ...this.params, ...params });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    }
}

export default new Api();
