export const tab = () => ({
    tabContainer: {
        borderBottom: '1px solid #e8e8e8'
    },
    indicator: {
        backgroundColor: '#1890ff'
    },
    table: {
        minWidth: 650,
        width: 'fit-contnt'
    },
    row: {
        height: '3.5rem'
    },
    tab: {
        borderBottom: '1px solid #e8e8e8',
        textTransform: 'none',
        minWidth: 72,
        fontWeight: '500',
        marginRight: '0',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1
        },
        '&:selected, &:Mui-selected': {
            color: '#1890ff',
            fontWeight: '500'
        },
        '&:focus': {
            color: '#40a9ff'
        }
    },
    overflow: {
        overflowY: 'scroll !important',
        '&: MuiTabs-scroller MuiTabs-fixed': {
            overflowY: 'scroll !important'
        }
    }
});

export const dialogStyles = theme => ({
    dialogTitle: {
        textAlign: 'center'
    },
    dialogAction: {
        padding: '16px'
    },
    button: {
        textTransform: 'none',
        fontSize: 14,
        color: 'white'
    },
    saveButton: {
        background: theme.palette.blue.main,
        '&:hover': {
            background: theme.palette.blue.main
        }
    },
    continueButton: {
        background: theme.palette.red.main,
        '&:hover': {
            background: theme.palette.red.main
        }
    }
});
