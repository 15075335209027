import api from './api';

class CoherentApi {
    
    static async checkPayment(id) {
        return api.request({
            method: 'GET',
            url: `/coherence/check-payment/${id}`
        });
    }
}

export default CoherentApi;
