import api from './api';

class ServicesApi {
    static async getCategories(clinic, skip = 0, count = 15) {
        return api
            .request({
                method: 'GET',
                url: `/categories?clinic=${clinic}&fromCustomer=true&skip=${skip}&count=${count}&isFromOrg=false`
            })
            .then(res => res)
            .catch(console.error);
    }

    static async getServices(clinic, category, start = 0, count = 15) {
        return api
            .request({
                method: 'GET',
                url: `/services/public?clinic=${clinic}&category=${category}&start=${start}&count=${count}`
            })
            .then(res => res)
            .catch(console.error);
    }

    static async getService(serviceId) {
        return api
            .request({
                method: 'GET',
                url: `/services/public/${serviceId}`
            })
            .then(res => res)
            .catch(console.error);
    }

    static async getCategory(categoryId) {
        return api
            .request({
                method: 'GET',
                url: `/categories/${categoryId}`
            })
            .then(res => res)
            .catch(console.error);
    }

    static async getAvailableTime(data) {
        return api
            .request({
                method: 'POST',
                url: '/practitioners/time',
                data
            })
            .then(res => res)
            .catch(console.error);
    }
}

export default ServicesApi;
