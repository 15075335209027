import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Selector from '../../common/Selector';

import { makeStyles, Divider } from '@material-ui/core';

import ClinicApi from '../../../api/clinicApi';
import { SERVICE } from '../../../constants/steps';
import { changeStep, changeLocation } from '../../../redux/actions/book-appointment';

import { locationStyles } from './styles';
import { getAddress } from './../../../utils/helpers/index';
import { LAST_CLINIC_ID } from '../../../constants/storageKeys';

export default function Location() {
    const dispatch = useDispatch();
    const [clinics, setClinics] = useState([]);

    const classes = makeStyles(locationStyles)();
    const history = useHistory();

    useEffect(() => {
        async function getClinics() {
            const res = await ClinicApi.listPublicClinics();
            setClinics(res);
        }
        getClinics();
    }, []);

    useEffect(() => {
        if (clinics && clinics.length === 1) {
            dispatch(changeLocation(clinics[0]));
            dispatch(changeStep(SERVICE));
            history.push(`/step/${clinics[0].id}`);
        }
        /*eslint-disable-next-line */
    }, [clinics]);

    return (
        <div className={classes.container}>
            <Selector className={classes.noScrollbar}>
                {clinics?.map(element => {
                    return (
                        <React.Fragment key={`${element.id}-option-selector`}>
                            <Selector.Row
                                onClick={() => {
                                    localStorage.setItem(LAST_CLINIC_ID, element.id);
                                    dispatch(changeLocation(element));
                                    dispatch(changeStep(SERVICE));

                                    history.push(`/step/${element.id}`);
                                }}
                            >
                                <Selector.Column>
                                    <span>
                                        <strong>{element.accountName}</strong>
                                    </span>
                                    <p className={classes.margin0px}>
                                        {getAddress(element)}
                                    </p>
                                </Selector.Column>
                            </Selector.Row>
                            <Divider />
                        </React.Fragment>
                    );
                })}
            </Selector>
        </div>
    );
}
