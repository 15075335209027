import { MOBILE } from '../../../constants/breakpoints';
export const serviceStyles = theme => ({
    servicesRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '1rem',
        '& span': {
            padding: '5% 0 0 5%',
            width: '100%'
        },
        [theme.breakpoints.up(MOBILE)]: {
            padding: '1rem',
            '& span': {
                padding: 0,
                width: '100%',
                marginBottom: '3%'
            }
        }
    },
    backButton: {
        padding: '0 0.5rem',
        backgroundColor: 'white',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer'
        },
        [theme.breakpoints.up(MOBILE)]: {
            padding: '0 0 3% 0'
        }
    },
    optionsList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& div': {
            border: '3px solid transparent'
        },
        '& div:nth-child(1)': {
            borderTopLeftRadius: '10px'
        },
        '& div:nth-last-child(2)': {
            borderBottomLeftRadius: '10px'
        },
        '& div:hover': {
            border: `3px solid ${theme.palette.primary.main}`
        },
        [theme.breakpoints.up(MOBILE)]: {
            border: '1px solid #ccc',
            borderRadius: '10px',
            height: '50vh',
            overflow: 'auto'
        }
    },
    actions: {
        display: 'none',
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center',
        marginTop: '2%',
        '& button': {
            marginRight: '2.5%',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.light
            }
        },
        [theme.breakpoints.up(MOBILE)]: {
            display: 'flex'
        }
    },
    optionName: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '1rem',
        '& button': {
            padding: 0,
            '& svg': {
                height: '18px',
                color: theme.palette.primary.main
            }
        }
    },
    name: {
        float: 'left'
    },
    pricing: {
        float: 'right'
    },
    info: {
        margin: '-0.5rem 0.5rem',
        padding: '0.5rem',
        '&:hover': {
            color: '#2b78e4'
        }
    },
    infoIcon: {
        height: '18px',
        weight: '18px'
    }
});
