import api from './api';

const errorHandler = error => {
    switch (true) {
        case error.includes('Invalid card number'):
            return 'Card number is not correct.';
        case error.includes('Invalid card expiry date'):
            return 'Expiry date is not correct.';
        case error.includes('Card verification code check failed'):
            return 'CVV is not correct.';
        case error.includes('Postal code check failed'):
            return 'Postal code is not correct.';
        default: return 'Unhandled request.';
    }
};

class CreditCardApi {
    static async listStoredCards(customerId) {
        return api
            .request({
                method: 'GET',
                url: `/square/get-customer-cards/${customerId}`
            })
            .catch(console.error);
    }

    static async addCreditCard(data) {
        return api
            .request({
                method: 'POST',
                url: '/square/add-customer-card',
                data
            })
            .catch(error => {
                throw errorHandler(error.data.message);
            });
    }

    static async deleteCreditCards(data, userId) {
        return api.request({
            method: 'DELETE',
            url: `/square/delete-customer-cards/${userId}`,
            data: {
                cards: data
            }
        });
    }

    static async checkIsConnected() {
        return api.request({
            method: 'GET',
            url: '/square/is-connected'
        });
    }

    static async getSeller() {
        return api
            .request({
                method: 'GET',
                url: '/square/get-seller'
            })
            .then(res => res);
    }
}

export default CreditCardApi;
