import api from './api';

class InvoiceApi {
    static saveInvoice(data) {
        return api
            .request({
                method: 'POST', url: '/invoice/create', data
            })
            .then(res => res);
    }

    static addInvoicePayment(data) {
        return api.request({
            method: 'POST',
            url: `/invoice/${data.invoice.id}/pay`,
            data
        });
    }

    static getByInvoice(invoiceId) {
        return api
            .request({
                method: 'GET',
                url: `/invoices/${invoiceId}`
            });
    }

    static hasPaymentIntent(invoiceId, data) {
        return api.request({
            method: 'POST',
            url: `/invoice/${invoiceId}/has-payment-intent`,
            data
        });
    }
}

export default InvoiceApi;
