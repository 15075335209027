export const dialog = () => ({
    dialog: {
        '& .MuiDialog-paper': {
            padding: '1rem 0'
        }
    },
    title: {
        alignSelf: 'center',
        '& h2': {
            fontSize: '1.3rem'
        },
        '& p': {
            textAlign: 'center'
        }
    },
    description: {
        color: 'black',
        textAlign: 'center'
    },
    btn: {
        backgroundColor: '#3083EC',
        color: 'white',
        padding: '0.5rem 1rem',
        '&:hover': {
            backgroundColor: '#0c62cf'
        }
    }
});
