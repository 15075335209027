import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory, useLocation } from 'react-router-dom';
import { MOBILE, TABLET } from '../../constants/breakpoints';
import { changeUserFormData, logout, updateUser, validateIsFormDirty } from '../../redux/actions/auth';
import { clearBookingInfo } from '../../redux/actions/book-appointment';
import BookAppointmentButton from '../common/BookAppointmentButton';
import Appointments from './appointments';
import Password from './password';
import PersonalDetails from './personal_details';
import { dialogStyles, tab } from './styles';
import * as queryString from 'querystring';
import querystring from 'querystring';
import StripeCreditCardConfirmation from '../book_appointment/confirm/stripeCreditCard/StripeCreditCardConfirmation';

const Menu = () => {
    const dispatch = useDispatch();
    const [stripeConfirmation, setStripeConfirmation] = useState(false);
    const classes = useStyles();
    const classesDialog = makeStyles(dialogStyles)();
    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);
    const [changePage, setChangePage] = useState({
        isModalVisible: false,
        value: 0
    });
    const [value, setValue] = useState(0);
    const isTablet = useMediaQuery(`(max-width:${TABLET}px)`);

    const { isUserFormDirty, isUserFormValid, userFormData, user } = useSelector(state => state.auth);

    const history = useHistory();
    const location = useLocation();
    const { stripeRedirect } = queryString.parse(location.search.substr(1));
    const { setup_intent_client_secret: clientSecret } = querystring.parse(location.search);

    if (stripeRedirect && value !== 1) {
        setValue(1);
    }

    useEffect(() => {
        if (clientSecret) {
            setStripeConfirmation(true);
        }
    }, [clientSecret]);

    const handleChange = (event, newValue) => {
        if (isUserFormDirty) {
            setChangePage({
                isModalVisible: true,
                value: newValue
            });

            return;
        }
        setValue(newValue);
    };

    function handleCloseWithoutSaving() {
        setValue(changePage.value);
        dispatch(changeUserFormData(user));
        dispatch(validateIsFormDirty(false));
        setChangePage({
            isModalVisible: false,
            value: 0
        });
    }

    function handleCloseSaving() {
        if (!isUserFormValid) {
            setChangePage({
                isModalVisible: false,
                value: 0
            });
            toastr.error('Missing required fields.', 'Fill all the required fields before proceeding');
            return;
        }
        const customer = { ...userFormData, email: userFormData.email?.address };
        const data = { id: userFormData.id, customer };
        dispatch(updateUser(data));
        setValue(changePage.value);
        setChangePage({
            isModalVisible: false,
            value: 0
        });
    }

    const TabPanel = props => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={3}>{children}</Box>}
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <div>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.overflow}
                    scrollButtons="auto"
                    variant="scrollable"
                    aria-label="scrollable auto tabs"
                >
                    <Tab
                        className={classes.tab}
                        disableRipple
                        label={isMobile || isTablet ? 'Appts' : 'Appointments'}
                    />
                    <Tab className={classes.tab} disableRipple label="Personal details"/>
                    <Tab className={classes.tab} disableRipple label="Password"/>
                    <Tab
                        className={classes.tab}
                        disableRipple
                        label="Log out"
                        onClick={() => {
                            dispatch(logout());
                            dispatch(clearBookingInfo());
                            history.replace('/');
                        }}
                    />
                    {!isMobile && !isTablet && <BookAppointmentButton/>}
                </Tabs>
                <Typography/>
            </div>
            <div>
                {TabPanel({ value, index: 0, children: <Appointments/> })}
                {TabPanel({ value, index: 1, children: <PersonalDetails/> })}
                {TabPanel({ value, index: 2, children: <Password/> })}
            </div>
            <Dialog open={changePage.isModalVisible}>
                <DialogTitle>
                    <Typography className={classesDialog.dialogTitle}>Unsaved Changes</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to continue?</Typography>
                </DialogContent>
                <DialogActions className={classesDialog.dialogAction}>
                    <Button
                        className={`${classesDialog.button} ${classesDialog.continueButton}`}
                        onClick={handleCloseWithoutSaving}
                    >
                        Continue without saving
                    </Button>
                    <Button
                        className={`${classesDialog.button} ${classesDialog.saveButton}`}
                        onClick={handleCloseSaving}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {stripeConfirmation &&
                <Dialog open={true}>
                    <DialogContent>
                        <StripeCreditCardConfirmation clientSecret={clientSecret} handleNext={() => {
                            setStripeConfirmation(false);
                            history.push('/app');
                        }}
                        closeConfirmation={() => {
                            setStripeConfirmation(false);
                        }}/>
                    </DialogContent>
                </Dialog>

            }
        </div>
    );
};

export default withStyles(tab)(Menu);
