export const locationStyles = () => ({
    container: {
        padding: '1rem',
        width: '100%',
        '& hr:nth-last-child(1)': {
            display: 'none'
        }
    },
    margin0px: {
        margin: 0
    },
    noScrollbar: {
        '&:hover': {
            '& div:nth-child(1)': {
                borderRadius: '10px 10px 0 0'
            },
            '& div:nth-last-child(2)': {
                borderRadius: '0 0 10px 10px'
            }
        }
    }
});
