import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MOBILE } from '../../../constants/breakpoints';

import { aditionalServiceDialogStyles as styles } from './styles';

function AdditionalServiceDialog({ onConfirm, onCancel, open, classes }) {
    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);

    return (
        <>
            {!isMobile ? (
                <Dialog open={!!open}>
                    <DialogContent>
                        <DialogContentText className={classes.text}>Add an additional service?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.button} onClick={onConfirm}>
                            Yes
                        </Button>
                        <Button className={classes.button} onClick={onCancel}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <>
                    {open && (
                        <div className={classes.dialogContent}>
                            <Typography className={classes.text}>Add an additional service?</Typography>
                            <div className={classes.mobileActions}>
                                <Button className={classes.button} onClick={onConfirm}>
                                    Yes
                                </Button>
                                <Button className={classes.button} onClick={onCancel}>
                                    No
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

AdditionalServiceDialog.propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdditionalServiceDialog);
