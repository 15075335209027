import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Step, StepLabel, Stepper, useMediaQuery, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

import Time from './time/Time';
import Service from './service';
import Staff from './staff/Staff';
import Confirm from './confirm/Confirm';
import Location from './location/Location';
import LoginModal from '../common/LoginModal';
import CreditCard from './confirm/credit_card/CreditCard';
import BookingAppointmentDetails from './BookingAppointmentDetails';
import ClinicApi from '../../api/clinicApi';
import ServicesApi from '../../api/servicesApi';

import { MOBILE } from '../../constants/breakpoints';
import {
    changeStep,
    setCurrentCategory,
    setCurrentTimeStep,
    setCurrentConfirmStep,
    changeService,
    changeLocation,
    updateAppointments,
    chooseTime,
    setCurrentDate
} from '../../redux/actions/book-appointment';
import {
    getCurrentStep,
    getCurrentCategory,
    getCurrentTimeStep,
    getCurrentConfirmStep,
    getCurrentAppointments
} from '../../redux/selectors/book-appointment';
import { CONFIRM, LOCATION, SERVICE, STAFF, TIME, CREDIT_CARD } from '../../constants/steps';

import { stepStyles } from './styles';
import InfoModal from '../common/InfoModal';
import { changeModalVisibility } from '../../redux/actions/auth';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import * as queryString from 'querystring';

const steps = [LOCATION, SERVICE, STAFF, TIME, CONFIRM];

export default function BookAppointment() {
    const [countdown, setCountdown] = useState({
        minutes: 5,
        seconds: 0
    });

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [infoText, setInfoText] = useState('');

    const dispatch = useDispatch();
    // const availableSteps = useAvailableSteps();
    // const socket = useSocket();

    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);
    const classes = makeStyles(theme => stepStyles(theme))();

    // The fallbackstep will get used once the user navigates past the currently routed steps
    // and stops relying on the route parameters.
    // We do this because not all routes are currently implemented (confirm, date/time, complete)
    // and want to retain original functionality for those steps.
    const fallbackStep = useSelector(getCurrentStep);
    const currentCategory = useSelector(getCurrentCategory);

    const history = useHistory();
    const { locationId, categoryId, serviceId } = useParams();

    const currentStep = useMemo(() => {
        if (![LOCATION, STAFF, SERVICE].includes(fallbackStep)) {
            return fallbackStep;
        }

        if (serviceId) return STAFF;
        if (categoryId) return SERVICE;
        if (locationId) return SERVICE;

        return LOCATION;
    }, [locationId, serviceId, categoryId, fallbackStep]);

    const currentAppointments = useSelector(getCurrentAppointments);
    const isBookAgain = useSelector(state => state.bookAppointment.isBookAgain);
    const isLinked = useSelector(state => state.bookAppointment.isLinked);
    const isReschedule = useSelector(state => state.bookAppointment.isReschedule);
    const hasLinked = useSelector(state => state.bookAppointment.linkedAppointments);
    const currentDate = useSelector(state => state.bookAppointment.currentDate);
    const modalVisibility = useSelector(state => state.auth.loginModalVisible);
    const location = useLocation();
    const { stripeRedirect } = queryString.parse(location.search.substr(1));


    const renderStepContent = {
        [LOCATION]: <Location/>,
        [SERVICE]: <Service/>,
        [STAFF]: <Staff/>,
        [TIME]: <Time/>,
        [CONFIRM]: <Confirm/>,
        [CREDIT_CARD]: <CreditCard/>
    }[currentStep];

    useEffect(() => {
        async function getClinics() {
            const res = await ClinicApi.listPublicClinics();

            const selectedClinic = res?.filter(c => c.id === locationId)[0];
            if (selectedClinic) {
                dispatch(changeLocation(selectedClinic));
            }
        }

        getClinics();
    }, [locationId, dispatch, currentStep]);

    useEffect(() => {
        async function getService() {
            if (!serviceId) return;
            const selectedService = await ServicesApi.getService(serviceId);

            if (!selectedService) return;

            dispatch(setCurrentCategory(selectedService.category));

            const tempCurrAppts = [];
            tempCurrAppts.push({
                service: selectedService,
                staff: null
            });
            if (!stripeRedirect && !isReschedule) {
                dispatch(updateAppointments(tempCurrAppts));
            }
            dispatch(changeService(selectedService));
        }

        getService();
    }, [serviceId, dispatch, stripeRedirect, isReschedule]);

    useEffect(() => {
        async function getCategory() {
            if (!categoryId) return;
            const selectedCategory = await ServicesApi.getCategory(categoryId);

            if (!selectedCategory) return;

            dispatch(setCurrentCategory(selectedCategory));
        }

        getCategory();
    }, [categoryId, dispatch]);

    useEffect(() => {
        async function getServices() {
            if (!serviceId) return;
            const selectedService = await ServicesApi.getService(serviceId);

            if (!selectedService) return;

            dispatch(setCurrentCategory(selectedService.category));

            const tempCurrAppts = [];
            tempCurrAppts.push({
                service: selectedService,
                staff: null
            });

            if (!stripeRedirect && !isReschedule) {
                dispatch(updateAppointments(tempCurrAppts));
            }
            dispatch(changeService(selectedService));
        }

        getServices();
    }, [serviceId, dispatch, stripeRedirect, isReschedule]);

    const subScreens = [
        {
            name: SERVICE,
            getter: useSelector(getCurrentCategory),
            setter: setCurrentCategory
        },
        {
            name: TIME,
            getter: useSelector(getCurrentTimeStep),
            setter: setCurrentTimeStep
        },
        {
            name: CONFIRM,
            getter: useSelector(getCurrentConfirmStep),
            setter: setCurrentConfirmStep
        }
    ];

    useEffect(() => {
        if (!isBookAgain && currentStep === CONFIRM) {
            const timer = setTimeout(() => {
                if (countdown.seconds === 0 && countdown.minutes === 0) {
                    return;
                }

                const _countdown = {
                    minutes: countdown.seconds === 0 ? countdown.minutes - 1 : countdown.minutes,
                    seconds: countdown.seconds === 0 ? 59 : countdown.seconds - 1
                };

                setCountdown(_countdown);
            }, 1000);

            return () => clearTimeout(timer);
        }

    }, [isBookAgain, currentStep, countdown]);

    const handleClickStep = step => {
        if (step === STAFF) {
            const lastAppointment = currentAppointments[currentAppointments.length - 1];
            const tempCurrAppts = [...currentAppointments];
            dispatch(changeService(lastAppointment.service));
            tempCurrAppts.splice(currentAppointments.length - 1, 1, {
                ...tempCurrAppts[currentAppointments.length - 1],
                staff: null
            });
            dispatch(updateAppointments(tempCurrAppts));
            dispatch(changeStep(step));
            return;
        }
        if (step === SERVICE) {
            const tempCurrAppts = [...currentAppointments];
            dispatch(changeService(null));
            tempCurrAppts.splice(currentAppointments.length - 1, 1);
            dispatch(updateAppointments(tempCurrAppts));
            dispatch(changeStep(step));
            return;
        }
        dispatch(changeStep(step));
    };

    const backToLastScreen = () => {
        const hasSubScreens = subScreens.find(screen => currentStep === screen.name);
        setCountdown({ minutes: 5, seconds: 0 });
        // Ideally each step would be separate so we could route them in isolation, but since we're not routing every step
        // we need to combine usage of react-router's history (push/pop/go) and the old redux steps tracking method
        if (hasSubScreens) {
            if (hasSubScreens.getter) {
                dispatch(hasSubScreens.setter(null));

                if (currentStep === 'time') {
                    dispatch(chooseTime(null));
                    dispatch(setCurrentDate(null));
                } else if (currentStep === 'service') {
                    if (categoryId && currentCategory.parentCategory) {
                        history.push(`/step/${locationId}/${currentCategory.parentCategory}`);
                        dispatch(setCurrentCategory(null));
                    } else {
                        history.push(`/step/${locationId}`);
                    }
                }
            } else {
                if (currentStep === 'time') {
                    history.push(`/step/${locationId}`);
                    dispatch(changeStep(LOCATION));
                    return;
                }

                if (currentStep === SERVICE && categoryId) {
                    const tempCurrAppts = [...currentAppointments];
                    dispatch(changeService(null));
                    tempCurrAppts.splice(currentAppointments.length - 1, 1);
                    dispatch(updateAppointments(tempCurrAppts));
                    history.push(`/step/${locationId}`);
                    return;
                }

                if (currentStep === SERVICE) {
                    history.push('/');
                    return;
                }

                if (currentStep === CONFIRM) {
                    history.push(`/step/${locationId}/${categoryId}/${serviceId}`);
                    handleClickStep(steps[steps.indexOf(currentStep) - 1]);
                    return;
                }
            }
        } else {
            if (![LOCATION, STAFF, SERVICE].includes(currentStep)) {
                handleClickStep(steps[steps.indexOf(currentStep) - 1]);
                return;
            }

            if (currentStep === STAFF) {
                const lastAppointment = currentAppointments[currentAppointments.length - 1];
                const tempCurrAppts = [...currentAppointments];
                dispatch(changeService(lastAppointment.service));
                tempCurrAppts.splice(currentAppointments.length - 1, 1, {
                    ...tempCurrAppts[currentAppointments.length - 1],
                    staff: null
                });
                dispatch(updateAppointments(tempCurrAppts));
                history.push(`/step/${locationId}/${categoryId}`);
                return;
            }

            if (currentStep === SERVICE && categoryId) {
                const tempCurrAppts = [...currentAppointments];
                dispatch(changeService(null));
                tempCurrAppts.splice(currentAppointments.length - 1, 1);
                dispatch(updateAppointments(tempCurrAppts));
                history.push(`/step/${locationId}`);
                return;
            }

            handleClickStep(steps[steps.indexOf(currentStep) - 1]);
        }
    };

    return (
        <>
            <div className={isMobile && currentStep === CONFIRM ? classes.stickyNav : null}>
                <Stepper
                    className={[
                        classes.stepper,
                        isMobile && currentStep === CONFIRM ? classes.stickyStepper : null
                    ].join()}
                    activeStep={steps.indexOf(currentStep)}
                    nonLinear
                    alternativeLabel={isMobile}
                >
                    <Step key={LOCATION}>
                        <StepLabel>Location</StepLabel>
                    </Step>
                    <Step key={SERVICE}>
                        <StepLabel>Services</StepLabel>
                    </Step>
                    <Step key={STAFF}>
                        <StepLabel>Staff</StepLabel>
                    </Step>
                    <Step key={TIME}>
                        <StepLabel>Time</StepLabel>
                    </Step>
                    <Step key={CONFIRM}>
                        <StepLabel>Confirm</StepLabel>
                    </Step>
                </Stepper>
                {currentStep === CONFIRM && !isBookAgain && (
                    <div className={classes.countdownMessage}>
                        <Typography variant="body1" component="span">
                            Your appointment will be held for {countdown.minutes}:
                            {(countdown.seconds === 0 || countdown.seconds.toString().length < 2) && '0'}
                            {countdown.seconds}
                        </Typography>
                    </div>
                )}
            </div>
            <div className={classes.stepContainer}>
                {currentStep === LOCATION || (currentStep === TIME && (isLinked || (hasLinked && hasLinked.length)) && !currentDate) ? (<></>) :
                    (
                        <div className={classes.backButton} onClick={() => backToLastScreen()}>
                            <ArrowBackIos/>
                            Back
                        </div>
                    )}

                <div className={classes.stepContent}>
                    <div className={classes.stepContent}>{renderStepContent}</div>
                    {(!isMobile || currentStep === CONFIRM) && (
                        <div className={classes.previewContent}>
                            <BookingAppointmentDetails/>
                        </div>
                    )}
                </div>
            </div>
            {!!modalVisibility.visible && <LoginModal setOpenInfoModal={setOpenInfoModal} setInfoText={setInfoText}/>}
            {openInfoModal &&
                <InfoModal
                    onClose={() => {
                        dispatch(changeModalVisibility({ visible: true }));
                        setOpenInfoModal(false);
                    }}
                    openInfoModal={openInfoModal} infoText={infoText}
                />}
        </>
    );
}
