import { MOBILE } from '../../../constants/breakpoints';
export default theme => ({
    confirmStepWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    confirmWrapper: {
        borderRadius: 10,
        marginTop: 16,
        height: '53vh',
        [theme.breakpoints.up(MOBILE)]: {
            margin: '16px .8rem 0 .8rem',
            border: '1px solid #ccc',
            height: '53vh',
            marginBottom: 0
        }
    },
    confirmBox: {
        padding: '2.2rem',
        width: '100%',
        marginTop: 16,
        marginBottom: 16,
        overflowY: 'scroll',
        height: '40vh'
    },
    confirmBoxTitle: {
        textAlign: 'center',
        marginBottom: '1.2rem'
    },
    confirmBoxSectionTitle: {
        fontWeight: 'bold'
    },
    confirmBoxSectionText: {
        marginTop: '.8rem',
        marginBottom: '3rem',
        display: 'inline-block',
        [theme.breakpoints.up(MOBILE)]: {
            marginBottom: '1.2rem'
        }
    },
    actions: {
        display: 'none',
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center',
        marginTop: '2%',
        margin: '0 .8rem 0 0',
        '& button': {
            marginRight: '2.5%',
            marginBottom: '2%',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.light
            }
        },
        [theme.breakpoints.up(MOBILE)]: {
            display: 'flex'
        }
    },
    agreeButtonWrapper: {
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
        backgroundColor: '#fff',
        padding: '.8rem',
        left: 0,
        right: 0,
        bottom: 0,
        [theme.breakpoints.up(MOBILE)]: {
            position: 'initial',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            padding: 0
        }
    },
    agreeButton: {
        textTransform: 'none',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontSize: '.875rem',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        }
    },
    cardHeader: {
        display: 'flex',
        width: '100%',
        justifyContent: ' center',
        marginBottom: '3vh'
    },
    cardTitle: {
        fontSize: '1.23rem'
    },
    bookAppointmentButton: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '5vh'
    },
    cardBorder: {
        height: '100%',
        width: '100%',
        display: 'column',
        borderRadius: 10,
        padding: '3vh 0px 1vh',
        [theme.breakpoints.up(MOBILE)]: {
            padding: '3vh 20px',
            border: '1px solid #ccc'
        }
    },
    termsAndConditionsDiv: {
        width: '100%'
    }
});

export const addCardStyles = theme => ({
    circularProgress: {
        marginTop: '50%',
        marginLeft: 'calc(50% - 16px)'
    },
    cardFormWrapper: {
        '& .sq-payment-form': {
            width: '50%',
            minWidth: '380px',
            maxWidth: '680px',
            margin: 'auto',
            [theme.breakpoints.down(MOBILE)]: {
                width: '100%',
                minWidth: 'unset'
            }
        }
    },
    warningText: {
        marginBottom: '4px',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:last-of-type': {
            marginBottom: '24px'
        }
    },
    inputField: {
        '& div': {
            width: '100%',
            '& div': {
                width: 'auto'
            },
            [theme.breakpoints.up(MOBILE)]: {
                width: '55%'
            }
        }
    },
    flexButtons: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        margin: 10,
        '& button': {
            textTransform: 'none'
        }
    },
    creditCardInput: {
        border: `1px solid ${theme.palette.gray.border}`
    },
    makeDefaultCheckbox: {
        padding: '9px 9px 9px 0'
    },
    submitButton: {
        width: '52%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'start',
        [theme.breakpoints.down(MOBILE)]: {
            width: '100%',
            marginTop: '8px',
            justifyContent: 'center'
        },
        '& button': {
            height: 'auto',
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3083ec',
            padding: '10px 16px',
            fontSize: '12px',
            boxSizing: 'border-box',
            transition:
                'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: 'Roboto, Arial, sans-serif',
            fontWeight: '500',
            lineHeight: '1.75',
            borderRadius: '4px',
            textTransform: 'none'
        }
    }
});

export const selectCardStyles = theme => ({
    cardSelector: {
        width: '100%'
    },
    cardRoot: {
        width: 'fit-content',
        margin: 'auto',
        marginBottom: 16,
        maxWidth: 680,
        minWidth: 380,
        [theme.breakpoints.down(MOBILE)]: {
            margin: '0 0 16px 0',
            width: '100%'
        }
    },
    cardInfoRoot: {
        [theme.breakpoints.down(MOBILE)]: {
            '& > div': {
                margin: 0,
                width: '100%'
            }
        },
        [theme.breakpoints.up(MOBILE)]: {
            '& > div': {
                margin: 0,
                width: '40%'
            }
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardSelectorRoot: {
        height: 'calc(100% + 8px)',
        boxSizing: 'border-box',
        fontSize: '14px'
    }
});
