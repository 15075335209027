import * as actions from '../actions';
import { LOCATION } from '../../constants/steps';

import { TIME_IO_FROM_BACK } from '../../constants/socket';

const initialState = {
    currentStep: LOCATION,
    currentAppointments: [],
    currentDate: null,
    currentTime: null,
    currentLocation: {
        id: null,
        name: null,
        address: null,
        street: null,
        city: null
    },
    currentServices: null,
    currentStaff: null,
    currentCategory: null,
    currentTimeStep: null,
    currentConfirmStep: null,
    times: [],
    availableStaff: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CLEAR_BOOKING_INFO:
            return {
                ...initialState
            };
        case actions.CHANGE_STEP:
            return { ...state, currentStep: action.payload };

        case actions.CHOOSE_TIME:
            return { ...state, currentTime: action.payload };

        case actions.CHANGE_LOCATION:
            return { ...state, currentLocation: action.payload };

        case actions.CLEAR_LOCATION:
            return { ...state, ...action.payload };

        case actions.SET_CURRENT_DATE:
            return { ...state, currentDate: action.payload };

        case actions.CHANGE_SERVICE:
            return {
                ...state,
                currentServices: action.payload
            };

        case actions.UPDATE_APPOINTMENTS:
            return {
                ...state,
                currentAppointments: action.payload
            };

        case actions.CHANGE_STAFF:
            return { ...state, currentStaff: action.payload };

        case actions.AVAILABLE_STAFF:
            return { ...state, availableStaff: action.payload };

        case actions.RESCHEDULE_APPOINTMENT:
            return action.payload;

        case actions.SET_CURRENT_CATEGORY:
            return { ...state, currentCategory: action.payload };

        case actions.SET_CURRENT_TIME_STEP:
            return { ...state, currentTimeStep: action.payload };

        case actions.SET_CURRENT_CONFIRM_STEP:
            return { ...state, currentConfirmStep: action.payload };

        case actions.SET_INITIAL_STATE:
            return initialState;

        case actions.TIMES_FETCHED:
            return { ...state, times: action.payload };

        case TIME_IO_FROM_BACK:
            return { ...state, times: action.payload };

        case actions.CHANGE_CURRENT_APPTS:
            return { ...state, currentAppointments: [...state.currentAppointments, action.payload] };

        case actions.OVERRIDE_APPOINTMENT:
            return action.payload;

        default:
            return state;
    }
};

export default reducer;
