import { makeStyles } from '@material-ui/core';
import React from 'react';
import { bannersStyles } from './style';

export default function StagingBanner() {
    const classes = makeStyles(bannersStyles)();
    return (
        <div className={classes.stagingBanner}>
            This is a staging environment! It includes unstable features and all data generated may be deleted without
            notice.
        </div>
    );
}
