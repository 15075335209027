import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../collums-components/components/common/Modal';

function InfoModal({ isVisible, setIsVisible, title, content }) {
    const closeModal = () => {
        setIsVisible({
            isVisible: false,
            content: '',
            title: ''
        });
    };

    return (
        <Modal
            isOpen={isVisible}
            title={title}
            size="xs"
            onCancel={closeModal}
            onClose={closeModal}
            hideCloseIcon={false}
            hideActions
        >
            {content}
        </Modal>
    );
}

InfoModal.propTypes = {
    setIsVisible: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    content: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default InfoModal;
