import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import BookAppointment from './components/book_appointment/BookAppointment';
import ThanksPage from './components/clinicLocation';
import Header from './components/common/Header';
import ForgotPassword from './components/forgot_password/ForgotPassword';
import Form from './components/form/Form';
import Menu from './components/home/Menu';

import { isAuthenticated } from './redux/selectors/auth';

const PrivateRoute = ({ exact, path, component }) => {
    const history = useHistory();
    const isAuth = useSelector(isAuthenticated);

    if (!isAuth) {
        history.replace('/');
    }

    return !isAuth ? null : <Route exact={exact} path={path} component={component} />;
};

const PublicRoute = ({ exact, path, component }) => <Route exact={exact} path={path} component={component} />;

export default function Routes() {
    return (
        <>
            <Header />
            <Switch>
                <PublicRoute exact component={BookAppointment} path="/" />

                <Route component={BookAppointment} path="/step/:locationId/:categoryId?/:serviceId?" />

                <Route exact component={ThanksPage} path="/location" />
                <Route exact component={ForgotPassword} path="/forgot-password" />
                <Route exact component={ForgotPassword} path="/user/reset-password/:token" />
                <PrivateRoute exact component={Menu} path="/app" />
                <Route exact path="/form/:formId" component={Form} />
            </Switch>
        </>
    );
}
