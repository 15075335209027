import api from './api';

class ClinicApi {
    static async listClinics() {
        return api
            .request({
                method: 'GET',
                url: '/clinics'
            })
            .then(res => res)
            .catch(console.error);
    }
    static async listPublicClinics() {
        return api
            .request({
                method: 'GET',
                url: '/public-clinics'
            })
            .then(res => res)
            .catch(console.error);
    }
}

export default ClinicApi;
