import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import classnames from 'classnames';
import { makeStyles, Box, useMediaQuery } from '@material-ui/core';
import { selectorStyle } from './styles';
import Column from './Column';
import Row from './Row';
import { MOBILE } from '../../../constants/breakpoints';
import { useSelector } from 'react-redux';

const Selector = ({ children, className }) => {
    const classes = makeStyles(selectorStyle)();
    const [displayFade, setDisplayFade] = useState({
        top: false,
        bottom: true
    });
    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);
    const isChoosingCategory = useSelector(state => !state.bookAppointment.currentCategory);
    const selectorRef = useRef();

    useEffect(() => {
        setDisplayFade({ top: false, bottom: false });
    }, [isChoosingCategory]);

    useLayoutEffect(() => {
        if (!selectorRef.current) return;

        const { scrollTop, offsetHeight, scrollHeight } = selectorRef.current;
        if (scrollTop > 0) {
            setDisplayFade({ ...displayFade, top: true });
        } else {
            setDisplayFade({
                top: false,
                bottom: true
            });
        }

        if (scrollTop + offsetHeight >= scrollHeight) {
            setDisplayFade({
                ...displayFade,
                bottom: false
            });
        }

        if (scrollTop > 0 && scrollTop + offsetHeight < scrollHeight) {
            setDisplayFade({
                top: true,
                bottom: true
            });
        }
        // eslint-disable-next-line
    }, [selectorRef.current?.scrollTop, selectorRef.current?.scrollHeight]);

    return (
        <>
            {isMobile && displayFade.top && <div className={classes.fadeTop} />}
            <Box ref={selectorRef} className={classnames(classes.container, className)}>
                {children}
            </Box>
            {isMobile && displayFade.bottom && <div className={classes.fadeBottom} />}
        </>
    );
};

Selector.Column = Column;
Selector.Row = Row;

export default Selector;
