import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CreditCardApi from '../../api/creditCardApi';

export const useCreditCard = () => {
    const [card, setCard] = useState('');
    const [isDefaultCard, setIsDefaultCard] = useState(false);
    const [postCode, setPostCode] = useState('');

    return {
        values: { card, isDefaultCard, postCode },
        setters: {
            setCard,
            setIsDefaultCard,
            setPostCode
        }
    };
};

export const useStoredCards = () => {
    const currentUser = useSelector(state => state.auth.user);
    const [storedCards, setStoredCards] = useState([]);
    useEffect(() => {
        CreditCardApi.listStoredCards(currentUser.id).then(setStoredCards).catch(console.error);
        /*eslint-disable-next-line */
    }, []);
    return storedCards;
};
