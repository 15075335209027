import React from 'react';
import { useSelector } from 'react-redux';

import Calendar from './Calendar';
import TimeChoose from './TimeChoose';
import { getCurrentTimeStep } from '../../../redux/selectors/book-appointment';
import { TIME_HOURS_AND_MINUTES } from '../../../constants/steps';

const TimeStep = ({ socket }) => {
    const currentTimeStep = useSelector(getCurrentTimeStep);

    return (
        <>
            {!currentTimeStep && <Calendar socket={socket} />}
            {currentTimeStep === TIME_HOURS_AND_MINUTES && <TimeChoose socket={socket} />}
        </>
    );
};

export default TimeStep;
