// FIXME

export const DISCONNECT = 'DISCONNECT_DEBUG';

export const DURATION_IO_FROM_FRONT = 'DURATION_IO_FROM_FRONT_DEBUG';
export const STAFF_IO_FROM_FRONT = 'STAFF_IO_FROM_FRONT_DEBUG';
export const CALENDAR_IO_FROM_FRONT = 'CALENDAR_IO_FROM_FRONT_DEBUG';
export const TIME_IO_FROM_FRONT = 'TIME_IO_FROM_FRONT_DEBUG';
export const CLINIC_IO_FROM_FRONT = 'CLINIC_IO_FROM_FRONT_DEBUG';

export const DURATION_IO_FROM_BACK = 'DURATION_IO_FROM_BACK_DEBUG';
export const STAFF_IO_FROM_BACK = 'STAFF_IO_FROM_BACK_DEBUG';
export const CALENDAR_IO_FROM_BACK = 'CALENDAR_IO_FROM_BACK_DEBUG';
export const TIME_IO_FROM_BACK = 'TIME_IO_FROM_BACK_DEBUG';
export const CLINIC_IO_FROM_BACK = 'CLINIC_IO_FROM_BACK_DEBUG';
