import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import styles from './styles';
import AppointmentApi from '../../../api/AppointmentApi';
import { useHistory } from 'react-router-dom';
import { getCurrentAppointments, getCurrentConfirmStep } from '../../../redux/selectors/book-appointment';
import { CREDIT_CARD } from '../../../constants/steps';
import { TC_MERGE_TAGS } from '../../../collums-constants/index';
import { setCurrentConfirmStep } from '../../../redux/actions/book-appointment';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { Promise } from 'bluebird';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import { addMinutes } from 'date-fns/esm';

// import { DISCONNECT } from '../../../constants/socket';
import OrganisationApi from './../../../api/organisationApi';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { getCurrencySymbol } from '../../../collums-components/helpers';
import { LAST_CLINIC_ID } from '../../../constants/storageKeys';
import AuthApi from '../../../api/authApi';
import { authUser } from '../../../redux/actions/auth';
import AppointmentsApi from '../../../api/AppointmentApi';
// import { differenceInHours } from 'date-fns';
import ServicesApi from '../../../api/servicesApi';
import CoherentCreditCard from './coherentCreditCardForm/CoherentCreditCard';

function Confirm({ classes }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [nextEnabled, setNextEnabled] = useState(false);
    const organisation = useSelector(state => state.auth.organisation);
    const renderCards = useSelector(getCurrentConfirmStep);
    // const cancellationPeriod = useSelector(state => state.auth.organisation?.lateCancellationPeriod || 48);
    const [termsAndConditionsText, setTermsAndConditionsText] = useState('');
    const boxReference = useRef(null);

    const history = useHistory();

    const [isCardRequired, setIsCardRequired] = useState(false);
    const [bookingDeposit, setBookingDeposit] = useState(false);
    const state = useSelector(state => state.bookAppointment);
    const { accountBalance } = useSelector(state => state.auth.userFormData);
    const currentAppointments = useSelector(getCurrentAppointments);
    const [price, setPrice] = useState(null);

    const getPrice = () => {
        return currentAppointments.reduce((acc, appt) => {
            if (appt.service?.defaultPrice === Infinity) {
                return acc;
            }
            if (appt.staff?.grossPrice) {
                return acc + appt.staff.grossPrice;
            }
            return acc + appt.service?.defaultPrice;
        }, 0);
    };

    useEffect(() => {
        (async () => {
            await AuthApi.getMe().then(user => {
                dispatch(authUser(user));
            }).catch(() => {
            });

            setPrice(getPrice());
            const cardRequirement = await OrganisationApi.checkCardRequirement();
            setIsCardRequired(cardRequirement);
            let { bookingDepositType, bookingDepositAmount } = await OrganisationApi.getPublicOrganisation();
            const serviceId = currentAppointments[0]?.service?.id;
            if (bookingDepositType && serviceId) {
                const service = await ServicesApi.getService(serviceId);
                if (service.bookingDepositType === 'No deposit') {
                    bookingDepositType = null;
                    bookingDepositAmount = null;
                }
                if (['Percentage', 'Fixed amount'].includes(service.bookingDepositType)) {
                    bookingDepositType = service.bookingDepositType;
                    bookingDepositAmount = service.bookingDepositAmount;
                }
            }

            setBookingDeposit({
                bookingDepositType,
                bookingDepositAmount
            });

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (organisation && organisation.termsAndConditionsText) {
            const tagValues = {
                '{LateCancellationType}': organisation.lateCancellationChargeType === 'AMOUNT' ? getCurrencySymbol() : '%',
                '{LateCancellationValue}': organisation.lateCancellationChargeValue,
                '{LateCancellationPeriod}': organisation.lateCancellationPeriod
            };

            let message = organisation.termsAndConditionsText;
            TC_MERGE_TAGS.forEach(tag => {
                while (message.search(tag) > -1) {
                    message = message.replace(tag, tagValues[tag]);
                }
            });
            setTermsAndConditionsText(message);
        }
    }, [organisation]);

    // useEffect(() => {
    //     return () => {
    //         if (socket) {
    //             socket.send({
    //                 type: DISCONNECT
    //             });
    //             socket.close();
    //         }
    //     };
    // }, [socket]);

    const handleOnScroll = e => {
        const OFFSET_MARGIN = 5;
        const { currentTarget } = e;
        if (currentTarget.offsetHeight + currentTarget.scrollTop >= currentTarget.scrollHeight - OFFSET_MARGIN) {
            setNextEnabled(true);
        }
    };

    const redirectToCreditCardStep = () => dispatch(setCurrentConfirmStep(CREDIT_CARD));

    const redirectToLocationStep = () => {
        setIsLoading(false);
        const currentLocationId = state.currentLocation?.id;
        if (currentLocationId) {
            localStorage.setItem(LAST_CLINIC_ID, currentLocationId);
        }
        history.replace('/location');
    };

    const rescheduleAppointment = async () => {
        try {
            const apptToReschedule = moment(getCurrentTimezonedDate(state.currentAppointments[0].event.start));
            await Promise.map(
                state.currentAppointments.filter(appts => !appts.isLinked),
                async appt => {
                    const apptToCompare = moment(getCurrentTimezonedDate(appt.event.start));
                    const difference = moment.duration(apptToCompare.diff(apptToReschedule)).asMinutes();
                    const startTime = addMinutes(state.currentTime, difference);
                    const data = {
                        currentTime: startTime,
                        currentAppointment: appt,
                        currentStaff: appt.staff,
                        currentLocation: {
                            ...state.currentLocation
                        }
                    };
                    const response = await AppointmentApi.rescheduleAppointment(appt.appointmentId, data);
                    if (response.error) {
                        throw new Error(response.message);
                    }
                }
            );
        } catch (errorMessage) {
            if (errorMessage.message) {
                toastr.error(errorMessage.message);
                return errorMessage.message || '';
            }
            if (errorMessage.data?.message) toastr.error(errorMessage.data?.message);
            return errorMessage.data?.message || '';
        } finally {
            setIsLoading(false);
        }
    };

    const handleShowCreditCards = async () => {
        try {
            setNextEnabled(false);
            const currentAppointments = state.currentAppointments;
            if (state.isReschedule && currentAppointments?.length && currentAppointments[0]?.appointmentId) {
                const appointment = await AppointmentsApi.getAppointment(currentAppointments[0]?.appointmentId);

                if (appointment) {
                    // const hoursToAppointment = () => differenceInHours(getCurrentTimezonedDate(appointment.event.start), getCurrentTimezonedDate());
                    // if (hoursToAppointment() > cancellationPeriod) {
                    const rescheduleError = await rescheduleAppointment();
                    if (!rescheduleError) {
                        redirectToLocationStep();
                    }
                    return;
                    // }
                }
            }

            if (isCardRequired || bookingDeposit.bookingDepositType) {
                redirectToCreditCardStep();
                return;
            }

            setIsLoading(true);
            const saveApptResponse = await AppointmentApi.saveAppointment(state);
            setNextEnabled(true);

            if (saveApptResponse.error) {
                toastr.error(saveApptResponse.message);
                return;
            }

            redirectToLocationStep();
        } catch (err) {
            toastr.error(err.message);
            redirectToCreditCardStep();
        } finally {
            setNextEnabled(true);
            setIsLoading(false);
        }
    };

    const handleNext = async () => {
        setIsLoading(true);
        if (state.isReschedule) {
            try {
                const rescheduleError = await rescheduleAppointment();
                if (!rescheduleError) {
                    redirectToLocationStep();
                }
            } catch (e) {
                console.error(e);
                setIsLoading(false);
            }
            return true;
        } else {
            try {
                const response = await AppointmentApi.saveAppointment({ ...state });
                setIsLoading(false);

                if (response.error) throw new Error(response.message);

                redirectToLocationStep();
            } catch (errorMessage) {
                console.error(errorMessage);
                toastr.error(errorMessage.message);
                setIsLoading(false);
            }
        }

    };

    useEffect(() => {
        if (termsAndConditionsText.length) {
            const OFFSET_MARGIN = 5;
            const { current } = boxReference;
            if (current && current.offsetHeight + current.scrollTop >= current.scrollHeight - OFFSET_MARGIN) {
                setNextEnabled(true);
            }
        }
    }, [termsAndConditionsText]);


    return (
        <>
            {isLoading && <LoadingScreen/>}
            {!renderCards && (
                <div className={classes.confirmStepWrapper}>
                    <div className={classes.confirmWrapper}>
                        <Box className={classes.confirmBox} ref={boxReference} onScroll={handleOnScroll} mr={2}>
                            <div className={classes.confirmBoxTitle}>
                                <Typography variant="h2" className={classes.confirmBoxSectionTitle}>
                                    Important Information
                                </Typography>
                            </div>

                            <div
                                className={classes.termsAndConditionsDiv}
                                dangerouslySetInnerHTML={{ __html: termsAndConditionsText }}
                            />

                        </Box>
                        <div className={classes.agreeButtonWrapper}>
                            <Button
                                variant="contained"
                                className={classes.agreeButton}
                                onClick={handleShowCreditCards}
                                disabled={!nextEnabled}
                            >
                                I understand and agree to these terms
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {renderCards === CREDIT_CARD && <CoherentCreditCard
                classes={classes}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                bookingDeposit={bookingDeposit}
                accountBalance={accountBalance}
                price={price}
                handleNext={handleNext}
            />
            }
        </>
    );
}

Confirm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Confirm);
