export const getCurrentStep = state => state.bookAppointment.currentStep;

export const getCurrentLocation = state => state.bookAppointment.currentLocation;

export const getCurrentServices = state => state.bookAppointment.currentServices;

export const getCurrentStaff = state => state.bookAppointment;

export const getCreditCard = state => state.creditCard.info;

export const getCurrentDate = state => state.bookAppointment.currentDate;

export const getCurrentTime = state => state.bookAppointment.currentTime;

export const getCurrentCategory = state => state.bookAppointment.currentCategory;

export const getCurrentTimeStep = state => state.bookAppointment.currentTimeStep;

export const getCurrentConfirmStep = state => state.bookAppointment.currentConfirmStep;

export const getCurrentAppointments = state => state.bookAppointment.currentAppointments;

export const getTimes = state => state.bookAppointment.times;

export const getState = state => state.bookAppointment;

export const isBookingAgain = state => state.bookAppointment.isBookAgain;
