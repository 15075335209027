import api from './api';

class AuthApi {
    static async auth(data) {
        return api
            .request({
                method: 'POST',
                url: '/login?from=customer',
                data: {
                    ...data,
                    email: data.email.toLowerCase()
                }
            })
            .then(res => res)
            .catch(err => err.data);
    }

    static async register(data) {
        return api
            .request({
                method: 'POST',
                url: '/user/customer/register',
                data: {
                    ...data,
                    email: data.email.toLowerCase()
                }
            })
            .then(res => res)
            .catch(err => err.data);
    }

    static async forgotPassword(email, existingUser) {
        return api
            .request({
                method: 'POST',
                url: `/user/forgot-password/${email.toLowerCase()}?existingUser=${existingUser || false}`
            })
            .catch(err => err.data);
    }

    static async changePassword(data) {
        return api
            .request({
                method: 'PUT',
                url: '/user/change-password',
                data
            })
            .then(res => res)
            .catch(err => err.data);
    }

    static async getMe() {
        return api
            .request({
                method: 'GET',
                url: '/user/me'
            })
            .then(res => res);
    }
}

export default AuthApi;
