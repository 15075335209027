import { MOBILE, TABLET } from '../../../constants/breakpoints';

export const personalDetailsStyles = theme => ({
    personalDetailsRoot: {
        width: '80%',
        padding: '2%',
        display: 'flex',
        borderRadius: '10px',
        border: '1px solid #ccc',
        margin: '1rem 0.8rem 0 0.8rem',
        [theme.breakpoints.down(MOBILE)]: {
            flexDirection: 'column',
            width: '90%'
        },
        '& p, span, label, .MuiInputBase-input': {
            fontSize: '0.875rem !important'
        }
    },
    personalDetailsSection: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        [theme.breakpoints.down(MOBILE)]: {
            width: '100%'
        }
    },
    inputRoot: {
        margin: '0.25rem',
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& p': {
            margin: 0,
            width: '30%'
        }
    },
    inputPhoneNumber: {
        [theme.breakpoints.down(TABLET)]: {
            padding: 0
        }
    },
    textRoot: {
        '& .MuiTextField-root, p:nth-child(2)': {
            width: '65%'
        },
        '& button': {
            padding: 0
        }
    },
    selectRoot: {
        '& .MuiInputBase-root': {
            width: '65%'
        }
    },
    phoneTypeSelector: {
        width: '25%'
    },
    marketing: {
        width: '65%',
        display: 'flex',
        justifyContent: 'flex-start',
        '& .MuiFormControlLabel-root': {
            marginRight: '15%'
        }
    },
    inputSection: {
        marginTop: '2rem'
    },
    creditCardSectionRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    creditCardHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontWeight: 'bold'
        },
        '& span': {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },
    creditCard: {
        marginTop: '2rem',
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        '& div': {
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            '& div': {
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                '& p:nth-child(2)': {
                    width: '60%',
                    [theme.breakpoints.down(MOBILE)]: {
                        width: '55%'
                    }
                }
            }
        },
        '& span': {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: 'fit-content',
        alignItems: 'center',
        margin: '0.5rem 0 1rem 1%',
        '& button': {
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            fontSize: '0.875rem !important',
            '&:hover': {
                backgroundColor: theme.palette.primary.light
            }
        },
        [theme.breakpoints.down(MOBILE)]: {
            margin: '0.5rem 0 1rem 5%'
        }
    }
});

export const modalStyles = theme => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '35vh 15vw',
        [theme.breakpoints.up(MOBILE)]: {
            margin: '25vh 20vw',
            height: 1000
        }
    },
    modalCard: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '5vh 5vw',
        [theme.breakpoints.up(MOBILE)]: {
            margin: '5vh 15vw'
        }
    },
    root: {
        width: 'fit-content',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none',
        padding: '16px'
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        margin: '0 5%',
        '& h4': {
            marginBottom: '5%'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        marginTop: '5%',
        '& button': {
            marginRight: '2.5%',
            textTransform: 'none'
        },
        '& button:nth-child(1)': {
            backgroundColor: theme.palette.white.main,
            color: theme.palette.black.main,
            border: `1px solid ${theme.palette.black.main}`,
            '&:hover': {
                backgroundColor: theme.palette.gray.lighter
            }
        },
        '& button:nth-child(2)': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
                backgroundColor: theme.palette.primary.light
            }
        }
    }
});
