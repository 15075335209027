import Axios from 'axios';

const hostParts = window.location.host.split('.');

// we do not default to staging, as this would show all 404 clinics as "ASKIN"
// including those not yet configured or misconfigured.
let clinicShortname = '';
if (hostParts.length === 5 || hostParts.length === 4) {
    clinicShortname = hostParts[0];
}

/** @type {string} */
const backendUrl = process.env.REACT_APP_BACKEND_URL || `https://${clinicShortname}.api.collums.co.uk`;

const authUrl = process.env.REACT_APP_AUTH_URL || `https://${clinicShortname}.auth.collums.co.uk`;

/** @type {string} */
const websocketUrl = process.env.REACT_APP_BACKEND_WS_URL || `wss://${clinicShortname}-ws.api.collums.co.uk`;

const defaultParams = {
    baseURL: backendUrl
};

class Api {
    constructor() {
        this.params = defaultParams;
        this.config({ headers: { Authorization: localStorage.getItem('token') } });
    }

    config(params) {
        this.params = { ...this.params, ...params };
    }
    resetToken(newToken) {
        this.config({ headers: { Authorization: newToken } });
    }

    updateToken() {
        try {
            const updatedToken = localStorage.getItem('token');
            if (
                updatedToken &&
                (!this.params.headers.Authorization || this.params.headers.Authorization !== updatedToken)
            ) {
                this.resetToken(updatedToken);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async request(params) {
        try {
            this.updateToken();
            const response = await Axios({ ...this.params, ...params });
            return response.data;
        } catch (error) {
            if (Axios.isCancel(error)) {
                throw error;
            }
            throw error.response;
        }
    }
}

const api = new Api();

/** @type {Object.<string, string>} */
export let links = {
    backendUrl,
    websocketUrl,
    authUrl,

    // define spa's as null until updated, preventing undefined property of an
    // object errors, but not setting it to a truthy value.
    calendarUrl: null,
    clientCardUrl: null,
    adminUrl: null,
    employeesUrl: null,
    marketingUrl: null,
    posUrl: null,
    stockUrl: null,
    reportingUrl: null,
    customerAppUrl: null,
    journeyUrl: null,

    // 0 means not queried
    // 1 means success
    // 2 means failed
    status: 0
};

// if we have it cached, then set it with known right away
let linkCache = localStorage.getItem('linkCache');
if (linkCache) {
    let _links = JSON.parse(linkCache);
    // check we can parse the linkCache and if so, also make sure the status is
    // not defined and successful
    if (_links && typeof _links.status !== 'undefined' && _links.status === 1) {
        links = _links;
    }
}

// still retrieve updated links, just in case
api.request({
    method: 'GET',
    url: '/public/links'
})
    .then(d => {
        links.authUrl = d.links['collums-auth'];
        links.calendarUrl = d.links['collums-calendar'];
        links.clientCardUrl = `${d.links['collums-calendar']}/customer`;
        links.adminUrl = d.links['collums-admin'];
        links.employeesUrl = d.links['collums-employees'];
        links.marketingUrl = d.links['collums-marketing'];
        links.posUrl = d.links['collums-pos'];
        links.stockUrl = d.links['collums-stock'];
        links.reportingUrl = d.links['collums-reporting'];
        links.customerAppUrl = d.links['customer-app'];
        links.journeyUrl = d.links['collums-patient-journey'];

        // anywhere referencing the links should always check the status code
        links.status = 1;

        // cache it to remove potential dependency
        localStorage.setItem('linkCache', JSON.stringify(links));
    })
    .catch(e => {
        links.status = 2;
        console.error('failed to get links from api', e);
    });

export function enqueueLoginRedirect() {
    if (links.status === 1) {
        window.location = `${links.authUrl}?redirect=${process.env.REACT_APP_NAME}`;
    } else if (links.status === 0) {
        // need to wait for the url to become available
        let waitForAuth = setInterval(() => {
            if (links.status !== 0) {
                clearInterval(waitForAuth);
            }

            if (links.status === 1) {
                window.location = `${links.authUrl}?redirect=${process.env.REACT_APP_NAME}`;
            } else if (links.status === 2) {
                console.error('something went wrong, could not get auth url');
            }
        }, 200);
    }
}

export function enqueueLogoutRedirect() {
    if (links.status === 1) {
        window.location = `${links.authUrl}?logout=true&redirect=${process.env.REACT_APP_NAME || 'calendar'}`;
    } else if (links.status === 0) {
        // need to wait for the url to become available
        let waitForAuth = setInterval(() => {
            if (links.status !== 0) {
                clearInterval(waitForAuth);
            }

            if (links.status === 1) {
                window.location = `${links.authUrl}?logout=true&redirect=${process.env.REACT_APP_NAME || 'calendar'}`;
            } else if (links.status === 2) {
                console.error('something went wrong, could not get auth url');
            }
        }, 200);
    }
}

export default api;
