import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';

import Marker from './Marker';

import { mapStyles } from './styles';

function ClinicLocation({ classes, defaultClinic }) {
    const currentLocation = useSelector(state => state.bookAppointment.currentLocation);

    const getCenter = useCallback(() => {
        const getLocationProperty = property => {
            const itemFromCurrentLocation = parseFloat(currentLocation?.address?.[property]);
            if (isNaN(itemFromCurrentLocation)) {
                return parseFloat(defaultClinic.address[property]);
            }
            return itemFromCurrentLocation;
        };

        return {
            lat: getLocationProperty('latitude'),
            lng: getLocationProperty('longitude')
        };
    }, [currentLocation, defaultClinic]);

    return (
        <div className={classes.mapRoot}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                defaultCenter={getCenter()}
                center={getCenter()}
                zoom={15}
            >
                <Marker lat={getCenter().lat} lng={getCenter().lng} />
            </GoogleMapReact>
        </div>
    );
}

ClinicLocation.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(mapStyles)(ClinicLocation);
