export const CHANGE_STEP = 'CHANGE_STEP';

export const CHOOSE_TIME = 'CHOOSE_TIME';

export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export const CLEAR_LOCATION = 'CLEAR_LOCATION';

export const CHANGE_CURRENT_APPTS = 'CHANGE_CURRENT_APPTS';

export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
export const SET_CURRENT_TIME_STEP = 'SET_CURRENT_TIME_STEP';
export const SET_CURRENT_CONFIRM_STEP = 'SET_CURRENT_CONFIRM_STEP';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const ADD_SERVICE = 'ADD_SERVICE';
export const CHANGE_SERVICE = 'CHANGE_SERVICE';
export const UPDATE_APPOINTMENTS = 'UPDATE_APPOINTMENTS';

export const LOGOUT = 'LOGOUT';
export const AUTH_USER = 'AUTH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_MODAL_VISIBILITY = 'CHANGE_MODAL_VISIBILITY';
export const TOUCH_USER_FORM = 'TOUCH_USER_FORM';
export const VALIDATE_IS_FORM_DIRTY = 'VALIDATE_IS_FORM_DIRTY';
export const VALIDATE_USER_FORM = 'VALIDATE_USER_FORM';
export const CHANGE_USER_FORM_DATA = 'CHANGE_USER_FORM_DATA';
export const CLEAR_BOOKING_INFO = 'CLEAR_BOOKING_INFO';
export const SET_ORGANISATION = 'SET_ORGANISATION';

export const CHANGE_STAFF = 'CHANGE_STAFF';
export const DELETE_STAFF = 'DELETE_STAFF';
export const AVAILABLE_STAFF = 'AVAILABLE_STAFF';

export const APPOINTMENTS_FETCHED = 'APPOINTMENTS_FETCHED';
export const TIMES_FETCHED = 'TIMES_FETCHED';

export const ISFETCHINGAPPOINTMENTS = 'ISFETCHINGAPPOINTMENTS';

export const RESCHEDULE_APPOINTMENT = 'RESCHEDULE_APPOINTMENT';
export const OVERRIDE_APPOINTMENT = 'OVERRIDE_APPOINTMENT';

//credit card
export const INFO = 'INFO';
