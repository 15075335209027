/* eslint-disable */
const { pick, flattenDeep, clone } = require('lodash');
const {
    WIDGET_TYPES,
    TITLES,
    WIDGET_GROUPS,
    COLLUMS_ACCESS_TIER,
    GENDERS,
    INVOICE_PAYMENT_STATUS,
    COLLUMS_DOMAIN,
    TREATMENT_TYPES
} = require('../index');
const getAnswersFromForm = require('./forms/getAnswersFromForm');
const DEFAULT_TREATMENT_PHOTOS_BY_GENDER = require('../treatmentImages');
const _ = require('lodash');

const calculateTaxValue = ({ tax, netPrice, quantity }) => {
    if (tax) return roundTwoDecimals(netPrice * tax) * quantity;
    return 0;
};

const calculateGrossPrice = ({ tax, netPrice, quantity }) => {
    if (tax) return roundTwoDecimals(netPrice * (tax + 1)) * quantity;
    return netPrice * quantity;
};

const calculateFinalPrice = ({ tax, netPrice, quantity, discount }) => {
    const grossPrice = calculateGrossPrice({ tax, netPrice, quantity });
    return grossPrice - discount;
};

const getTaxValue = tax => {
    if (tax.name) {
        // it is an object
        return parseInt(tax.rate, 10);
    }

    if (tax.toString().indexOf('%') > -1) {
        if (!parseInt(tax, 10)) return 0;
        else return parseInt(tax, 10);
    } else {
        if (tax !== 0) return `${tax}% VAT`;
        else return 'Exempt (0%)';
    }
};
const getResourceAndLocsUsingTax = (resource, taxId, allClinics) => {
    const taxProp = 'taxType' in resource ? 'taxType' : 'tax';
    const formatedServ = _.cloneDeep(resource);
    Object.assign(formatedServ, { locationsUsingTax: [] });

    const locationalTax = resource.locations
        .map(loc => {
            if (loc[taxProp]?.toString() === taxId.toString()) {
                const currentClinic = allClinics.find(clinic => clinic._id.toString() === loc.clinic.toString());
                return {
                    clinicName: currentClinic.accountName,
                    clinicId: currentClinic._id
                };
            }
            return false;
        })
        .filter(el => el);
    delete formatedServ.locations;
    formatedServ.locationsUsingTax = locationalTax;
    return formatedServ;
};

const validateActiveArchived = (value, mappedProperty, item) => {
    if (
        (value === true && mappedProperty === 'isActive' && item[mappedProperty] === false) ||
        (value === true && mappedProperty === 'active' && item[mappedProperty] === false) ||
        (value === false && mappedProperty === 'archived' && item[mappedProperty] === true)
    ) {
        throw new Error(
            `You cannot change this item, it should be ${
                mappedProperty === 'active' || mappedProperty === 'isActive' ? 'activated' : 'unarchived'
            } at organisation level`
        );
    }
};

const validateLocationPrice = (oldItem, payload, isFromOrg, clinic, isFromCourse) => {
    let result = [];
    const oldNetPrice = isFromCourse ? oldItem.netPrice : oldItem.defaultNetPrice;
    const oldGrossPrice = isFromCourse ? oldItem.salePrice : oldItem.defaultGrossPrice;
    const oldServiceTax = isFromCourse ? oldItem.tax : oldItem.taxType;
    if (isFromOrg) {
        const payloadNetPrice = isFromCourse ? payload.netPrice : payload.defaultNetPrice;
        const payloadGrossPrice = isFromCourse ? payload.salePrice : payload.defaultGrossPrice;
        const payloadTax = isFromCourse ? payload.tax : payload.taxType;
        const locNetPrice = isFromCourse ? 'netPrice' : 'netPrice';
        const locGrossPrice = isFromCourse ? 'salePrice' : 'grossPrice';
        const locTax = isFromCourse ? 'tax' : 'taxType';

        result = payload.locations.map(loc => {
            const newLocation = _.cloneDeep(loc);

            newLocation[locNetPrice] = payloadNetPrice || oldNetPrice;
            newLocation[locGrossPrice] = payloadGrossPrice || oldGrossPrice;
            newLocation[locTax] = payloadTax || oldServiceTax;

            return newLocation;
        });
    } else if (!isFromOrg && clinic) {
        result = oldItem.locations.map(loc => {
            if (loc.clinic.toString() === clinic.toString()) {
                return payload.locations.find(loc => loc.clinic.toString() === clinic.toString());
            } else {
                return loc;
            }
        });
    }
    return result;
};

const validateLocationProp = (oldItem, payload, isFromOrg, clinic, prop) => {
    let result = [];

    if (!oldItem?.locations?.length && payload?.locations?.length) {
        oldItem.locations = payload.locations;
        return (result = payload.locations);
    }
    if (isFromOrg) {
        result = payload.locations.map(loc => {
            const newLocation = _.cloneDeep(loc);
            newLocation[prop] = payload[prop];
            return newLocation;
        });
    } else if (!isFromOrg && clinic) {
        result = oldItem.locations.map(loc => {
            if (loc?.clinic?.toString() === clinic?.toString()) {
                return payload.locations.find(loc => loc.clinic.toString() === clinic.toString());
            } else {
                return loc;
            }
        });
    }
    return result;
};

const validateBooleanProp = (
    oldItem,
    payload,
    isFromOrg,
    clinic,
    prop,
    fieldName,
    autoAssign = true,
    clinicsCount = null
) => {
    return payload.locations.map(loc => {
        if (!isFromOrg) {
            if (
                loc.clinic.toString() === clinic.toString() &&
                loc[prop] === true &&
                oldItem[prop] === false &&
                ((clinicsCount !== null && clinicsCount > 1) || clinicsCount === null)
            ) {
                if (fieldName === 'Allow negative') {
                    throw new Error(
                        `"Allow negative stock" cannot be saved. It must first be selected in Organisation Settings.`
                    );
                }
                throw new Error(`You cannot activate '${fieldName}'. This feature is not enabled in the organisation.`);
            } else {
                return loc;
            }
        } else {
            if (autoAssign) {
                if (payload[prop] === undefined) {
                    loc[prop] = payload.locations[0][prop];
                } else {
                    loc[prop] = payload[prop];
                }
            }
            return loc;
        }
    });
};

const changeProperty = (item, property, value, isFromOrg, clinic, clinicsCount = null) => {
    let locations = [];
    if (isFromOrg || clinicsCount === 1) {
        locations = item.locations.map(loc => {
            if (!loc) return loc;
            if (property === 'archived') {
                loc.active = false;
            }
            loc[property] = value;
            return loc;
        });
    } else if (!isFromOrg && clinic) {
        validateActiveArchived(value, property, item);
        locations = item.locations.map(loc => {
            if (loc.clinic?.toString() === clinic.toString()) {
                loc[property] = value;
                if (property === 'archived' && value) {
                    loc.active = false;
                }
                if (property === 'active' && !value) {
                    loc.showOnline = false;
                }
                return loc;
            } else {
                return loc;
            }
        });
    }
    return locations;
};

const applyDiscount = (val1, val2) => {
    if (val1 < 0 || val2 < 0) return val1 + val2;
    return val1 - val2;
};

const roundNumber = numberParam => {
    return Math.round(Number((numberParam || 0).toFixed(3)) * 100) / 100;
};

const roundTwoDecimals = number => {
    return Math.round(100 * number) / 100;
};

const calcItemValues = (invItem, recalcForRefund = false, skipDiscount = false) => {
    if (typeof invItem !== 'object') return invItem;

    const quantity = invItem.quantity || 1;

    const taxPercentage = invItem.tax;

    const discount = invItem.discount || 0;

    const itemNetPrice = invItem.netPrice || 0;

    let netPrice;
    let tax;
    let grossPrice;

    if (recalcForRefund && invItem?.paymentStatus === 'Refund') {
        if (skipDiscount) {
            netPrice = itemNetPrice * quantity;
        } else {
            netPrice = itemNetPrice * quantity + discount;
        }
        tax = (() => {
            if (!taxPercentage) return 0;
            return netPrice * taxPercentage;
        })();
        grossPrice = netPrice + tax;
    } else {
        netPrice = applyDiscount(itemNetPrice * quantity, discount / (1 + taxPercentage));

        tax = (() => {
            if (!taxPercentage) return 0;
            return applyDiscount(
                itemNetPrice * quantity * taxPercentage,
                (discount * taxPercentage) / (1 + taxPercentage)
            );
        })();
        grossPrice = applyDiscount(roundNumber(itemNetPrice * (1 + taxPercentage)) * quantity, discount);
    }

    Object.assign(invItem, {
        quantity,
        netPrice: roundNumber(netPrice),
        tax: roundNumber(tax),
        grossPrice: roundNumber(grossPrice),
        discount: roundNumber(discount)
    });
    return invItem;
};

const getLocationItem = (item = {}, clinic = '', field = '') => {
    const locationItem = item?.locations?.find(loc => loc?.clinic?.toString() === clinic?.toString());
    if (!locationItem) return item;
    if (field && !locationItem[field]) return item;
    return locationItem;
};

const getTotalsFromInvoiceItems = (invoiceItems, source, isRefunded) => {
    const calcTotalPrice = () => {
        if (isRefunded) {
            return invoiceItems?.reduce((acc, current) => {
                return acc + current.refundedAmount - Math.abs(current.discount);
            }, 0);
        }

        return invoiceItems?.reduce((acc, current) => {
            const currentNetPrice = current.refundAmount ? current.refundAmount / (1 + current.tax) : current.netPrice;
            const price = roundTwoDecimals(
                current.quantity *
                    (currentNetPrice +
                        calculateTaxValue({
                            tax: current.tax,
                            netPrice: currentNetPrice,
                            quantity: 1
                        }))
            );
            // apply discount on netPrice for invoices modals
            return acc + price;
        }, 0);
    };
    const calcTaxesTotal = () => {
        if (isRefunded) {
            return invoiceItems?.reduce((acc, current) => {
                const taxValue = current?.tax;
                return acc + (current.refundedAmount / (1 + taxValue)) * taxValue;
            }, 0);
        }

        return invoiceItems?.reduce((acc, current) => {
            const currentNetPrice = current.refundAmount ? current.refundAmount / (1 + current.tax) : current.netPrice;
            return (
                acc +
                    calculateTaxValue({
                        tax: current.tax,
                        netPrice: current.quantity * currentNetPrice,
                        quantity: 1
                    }) || 0
            );
        }, 0);
    };

    const taxesTotal = calcTaxesTotal();
    const totalPrices = calcTotalPrice();
    const totalDiscount = invoiceItems?.reduce((acc, current) => {
        return acc + current.discount ?? 0;
    }, 0);
    const itemsSubTotal = isRefunded ? totalPrices - +taxesTotal : totalPrices - taxesTotal;

    return {
        invoiceSubTotal: itemsSubTotal,
        invoiceDiscount: totalDiscount,
        invoiceTax: taxesTotal,
        invoiceTotal: applyDiscount(totalPrices, totalDiscount)
    };
};

const calcDateFromType = (quantity, type) => {
    const now = new Date();

    if (type === 'days') {
        now.setDate(now.getDate() + quantity);
    } else if (type === 'weeks') {
        now.setDate(now.getDate() + quantity * 7);
    } else {
        // months
        var date = now.getDate();
        now.setMonth(now.getMonth() + +quantity);
        if (now.getDate() !== date) {
            now.setDate(0);
        }
    }

    return now;
};

const validateFormFields = fields => {
    const fieldsError = fields.map((field, index) => {
        const errors = [];

        const filteredId = fields.filter(({ id }) => id === field.id);
        if (filteredId.length > 1) {
            errors.push('Duplicated ID');
        }

        if (
            [WIDGET_TYPES.PARAGRAPH_WIDGET, WIDGET_TYPES.TITLE_WIDGET, WIDGET_TYPES.IMAGE_WIDGET].includes(field.type)
        ) {
            if (field.isRequired) {
                errors.push(`Field ${field.type} at position ${index + 1} should be not required`);
            }
        }

        if (
            [
                WIDGET_TYPES.AUTOCOMPLETE_WIDGET,
                WIDGET_TYPES.SELECT_WIDGET,
                WIDGET_TYPES.LIST_WIDGET,
                WIDGET_TYPES.RADIO_AUTOCOMPLETE_WIDGET,
                WIDGET_TYPES.RADIO_WIDGET
            ].includes(field.type)
        ) {
            if (!(field.options || []).length && !field.useDrugList) {
                errors.push(`field ${field.type} at position ${index + 1} should have valid options`);
            }
        }

        return errors;
    });

    return flattenDeep(fieldsError);
};

const filterFieldsData = fields => {
    const generalProperties = ['id', 'isRequired', 'title', 'type', 'group'];

    const mappedData = fields.map(field => {
        if (!field.group || field.group === WIDGET_GROUPS.NONE) {
            ['mhText', 'mhPositiveSelection', 'mhSelection'].forEach(key => {
                if (field[key] !== undefined) field[key] = undefined;
            });
        }
        switch (field.type) {
            case WIDGET_TYPES.PARAGRAPH_WIDGET:
                return pick(field, ['id', 'isRequired', 'title', 'type', 'content', 'group']);
            case WIDGET_TYPES.IMAGE_WIDGET:
                return pick(field, ['id', 'isRequired', 'title', 'type', 'src', 'group', 'alignment', 'image_size']);
            case WIDGET_TYPES.AUTOCOMPLETE_WIDGET:
                return pick(field, [
                    'id',
                    'isRequired',
                    'title',
                    'type',
                    'options',
                    'multiple',
                    'autocompleteSearch',
                    'labelAfter',
                    'useDrugList',
                    'group',
                    'mhSelection'
                ]);
            case WIDGET_TYPES.SELECT_WIDGET:
                return pick(field, [
                    'id',
                    'isRequired',
                    'title',
                    'type',
                    'options',
                    'useDrugList',
                    'group',
                    'multiple'
                ]);
            case WIDGET_TYPES.CHECKBOX_WIDGET:
                return pick(field, ['id', 'isRequired', 'title', 'type', 'value', 'group', 'mhSelection']);
            case WIDGET_TYPES.RADIO_WIDGET:
                return pick(field, [
                    'id',
                    'isRequired',
                    'title',
                    'type',
                    'options',
                    'hasDefaultValue',
                    'value',
                    'group',
                    'mhSelection',
                    'mhPositiveSelection'
                ]);
            case WIDGET_TYPES.TITLE_WIDGET:
                return pick(field, generalProperties);
            case WIDGET_TYPES.TEXT_AREA_WIDGET:
                return pick(field, generalProperties);
            case WIDGET_TYPES.TEXT_WIDGET:
                return pick(field, ['id', 'isRequired', 'title', 'type', 'group', 'mhText']);
            case WIDGET_TYPES.BLOCK_WIDGET:
                return pick(field, ['id', 'size', 'type', 'isRequired']);
            case WIDGET_TYPES.LIST_WIDGET:
                return pick(field, [
                    'id',
                    'isRequired',
                    'title',
                    'type',
                    'textTitle',
                    'options',
                    'multiple',
                    'autocompleteSearch',
                    'useDrugList',
                    'autoCompleteTitle',
                    'modalTitle',
                    'modalText',
                    'group',
                    'mhSelection',
                    'mhText'
                ]);
            case WIDGET_TYPES.RADIO_TEXT_WIDGET:
                return pick(field, [
                    'id',
                    'isRequired',
                    'isSubFieldRequired',
                    'title',
                    'type',
                    'textTitle',
                    'hasDefaultValue',
                    'isVisible',
                    'group',
                    'mhText',
                    'mhPositiveSelection'
                ]);
            case WIDGET_TYPES.RADIO_LIST_WIDGET:
                return pick(field, [
                    'id',
                    'isRequired',
                    'isSubFieldRequired',
                    'title',
                    'type',
                    'textTitle',
                    'options',
                    'multiple',
                    'autocompleteSearch',
                    'useDrugList',
                    'autoCompleteTitle',
                    'modalTitle',
                    'modalText',
                    'hasDefaultValue',
                    'isVisible',
                    'group',
                    'mhPositiveSelection',
                    'mhSelection',
                    'mhText'
                ]);
            case WIDGET_TYPES.RADIO_AUTOCOMPLETE_WIDGET:
                return pick(field, [
                    'id',
                    'isRequired',
                    'isSubFieldRequired',
                    'title',
                    'type',
                    'textTitle',
                    'options',
                    'labelAfter',
                    'multiple',
                    'autocompleteSearch',
                    'useDrugList',
                    'autoCompleteTitle',
                    'hasDefaultValue',
                    'isVisible',
                    'group',
                    'mhSelection',
                    'mhPositiveSelection'
                ]);
            default:
                return field;
        }
    });

    return mappedData;
};

const late_cancellation = (cancellation_period, cancellation_charge, currency) =>
    `Changes to this appointment are within ${Math.round(cancellation_period) ||
        48} hours which will result in a ${currency || '£'}${cancellation_charge ||
        0} charge to your card or, in the case of a treatment that is part of a course, forfeiting the treatment. Are you sure you want to continue?`;

const late_cancellation_template = `Changes to this appointment are within {LateCancellationPeriod} hours which will result in a {LateCancellationFee} charge to your card or, in the case of a treatment that is part of a course, forfeiting the treatment. Are you sure you want to continue?`;

const shortStr = (text, maxLetters = 24) => {
    if (text.length <= maxLetters) {
        return text;
    }
    const splittedText = text.substr(0, maxLetters - 3);
    return splittedText + '...';
};

const getFullName = user => {
    const properties = [user.title, user.firstName, user.middleName, user.surname].filter(
        el => el && el !== TITLES.CUSTOM
    );
    return properties.join(' ');
};

const getName = user => {
    const properties = [user.firstName, user.surname].filter(el => el);
    return properties.join(' ');
};

const formatServiceFormData = value => {
    const errors = [];
    const getId = form => (typeof form === 'object' ? form.id : form);

    value.customerForms = (value.customerForms || []).filter(customerForm => customerForm).map(getId);
    value.practitionerForms = (value.practitionerForms || []).filter(practitionerForm => practitionerForm).map(getId);
    value.formsToEmail = (value.formsToEmail || [])
        .filter(el => el?.form)
        .map(formToEmail => {
            const form = getId(formToEmail.form);
            return {
                form,
                sendOn: formToEmail.sendOn
            };
        });
    value.defaultTreatmentRecord = value.defaultTreatmentRecord || TREATMENT_TYPES['GENERAL'];

    const isFormsToEmailInvalid = value.formsToEmail.some(formItem => {
        return !formItem.form && !formItem.sendOn;
    });

    if (isFormsToEmailInvalid) {
        errors.push('Missing option on Forms to Email');
    }

    if (!value.defaultTreatmentRecord) {
        errors.push('Missing Treatment Form');
    }

    return errors;
};
const getExtension = path => {
    return path
        .split('')
        .reverse()
        .join('')
        .split('.')[0]
        .split('')
        .reverse()
        .join('');
};
const getFileType = (file, defaultType) => {
    const fileType = ((file || {}).name?.split('.') || [])[1];
    return fileType || defaultType;
};

const getEnvironment = () => {
    // eslint-disable-next-line no-undef
    if (window.location.hostname !== 'localhost' && window.location.hostname !== '192.168.15.204') {
        return { path: '/', domain: COLLUMS_DOMAIN };
    } else {
        return {};
    }
};

const isValidEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const standardizeTimeSize = time => {
    if (Number(time) < 10) {
        return `0${time}`;
    }
    return time;
};

const isAvailableByPlan = minimumPlan => {
    const accessTierList = Object.values(COLLUMS_ACCESS_TIER);

    const findAccessTierIndex = tierName => accessTierList.findIndex(planName => planName === tierName);

    const minimumPlanIndex = findAccessTierIndex(minimumPlan);
    const currentPlanIndex = findAccessTierIndex(
        process.env.REACT_APP_ACCESS_TIER || process.env.ACCESS_TIER || COLLUMS_ACCESS_TIER.STARTUP_ACCESS
    );
    return currentPlanIndex >= minimumPlanIndex;
};

const isValidMongoIdString = idToCompare => {
    return typeof idToCompare === 'string' && Boolean(idToCompare) && idToCompare.match(/^[0-9a-fA-F]{24}$/);
};

const getDefaultTreatmentPhotoByGender = gender => {
    if (!gender || ![GENDERS.MALE, GENDERS.MALE_TRANS].includes(gender)) {
        return DEFAULT_TREATMENT_PHOTOS_BY_GENDER.FEMALE[0];
    }
    return DEFAULT_TREATMENT_PHOTOS_BY_GENDER.MALE[0];
};

/**
 * Check if the refunded item have a refunded amount change the netPrice to refundedAmount
 * @param {object} item
 * */
const checkRefundedAmount = (item, skipDiscount = false) => {
    if (item.refundedAmount > 0 || item.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND) {
        const newItem = clone(item);
        if (skipDiscount) {
            newItem.netPrice = (newItem.refundedAmount ?? 0) / (1 + newItem.tax);
            newItem.discount = 0;
            return newItem;
        }
        newItem.netPrice = (newItem.refundedAmount ?? 0) / (1 + newItem.tax) + Math.abs(newItem.discount);
        return newItem;
    }
    return item;
};

/**
 * Check if the first parameter match with the service commission
 * @param {object} invItem
 * @param {string} serviceCommissionType
 * @returns {boolean}
 * */
const checkServiceCommission = (invItem, serviceCommissionType) => {
    if (!serviceCommissionType || (invItem.from === 'SOLD' && invItem.isRedemption)) return false;

    if (invItem.from === 'BOTH') return true;
    if (serviceCommissionType === 'BOTH' && invItem.from) return true;

    return invItem.from === serviceCommissionType;
};

/**
 * Transform a text into a valid mongo object prop key
 * @param {string} text
 * @returns {string}
 * */
const toObjKey = text => text.toLowerCase().replace(/\s/g, '');

module.exports = {
    getTaxValue,
    calculateTaxValue,
    calculateGrossPrice,
    calculateFinalPrice,
    calcItemValues,
    getTotalsFromInvoiceItems,
    roundNumber,
    roundTwoDecimals,
    calcDateFromType,
    getResourceAndLocsUsingTax,
    validateActiveArchived,
    validateLocationPrice,
    validateLocationProp,
    validateBooleanProp,
    changeProperty,
    getLocationItem,
    filterFieldsData,
    validateFormFields,
    getAnswersFromForm,
    getExtension,
    late_cancellation,
    late_cancellation_template,
    shortStr,
    getName,
    getFullName,
    formatServiceFormData,
    getFileType,
    isValidEmail,
    standardizeTimeSize,
    isAvailableByPlan,
    isValidMongoIdString,
    getDefaultTreatmentPhotoByGender,
    checkRefundedAmount,
    checkServiceCommission,
    toObjKey,
    getEnvironment
};
