import React, { useState } from 'react';
import {
    Box,
    Button,
    InputLabel,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    makeStyles,
    Typography
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useSelector } from 'react-redux';
import AuthApi from '../../../api/authApi';
import ChangePasswordForm from '../../common/ChangePasswordForm';
import { passwordValidator } from '../../../utils/helpers/index';
import { passwordStyles as styles } from './styles';

export default function Password() {
    const user = useSelector(state => state.auth.user);
    const classes = makeStyles(styles)();

    const [displayInfo, setDisplayInfo] = useState({
        show: false,
        text: '',
        isRed: false
    });

    const [newPassword, setNewPassword] = useState({
        value: '',
        showPassword: false
    });

    const [confirmNewPassword, setConfirmNewPassword] = useState({
        value: '',
        showPassword: false
    });

    const [currentPassword, setCurrentPassword] = useState({
        value: '',
        showPassword: false
    });

    function handleCurrentPasswordClick() {
        setCurrentPassword({
            ...currentPassword,
            showPassword: !currentPassword.showPassword
        });
    }

    function handleCurrentPasswordChange(event) {
        setCurrentPassword({
            ...currentPassword,
            value: event.target.value
        });
    }

    async function handleOnClick() {
        if (newPassword.value !== confirmNewPassword.value) {
            setDisplayInfo({
                show: true,
                text: 'Passwords do not match.',
                isRed: true
            });

            return;
        }

        if (newPassword.value === '' || confirmNewPassword.value === '') {
            setDisplayInfo({
                show: true,
                text: 'New password cannot be empty.',
                isRed: true
            });

            return;
        }

        if (!new RegExp(passwordValidator).test(newPassword.value)) {
            setDisplayInfo({
                show: true,
                text: 'New password does not meet requirements.',
                isRed: true
            });

            return;
        }

        const data = {
            newPassword: newPassword.value,
            currentPassword: currentPassword.value,
            userId: user.id
        };

        const response = await AuthApi.changePassword(data);

        if (response.statusCode === 200) {
            setDisplayInfo({
                show: true,
                text: response.message
            });
        } else if (response.statusCode === 401) {
            setDisplayInfo({
                show: true,
                text: response.message,
                isRed: true
            });
        } else {
            return;
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                <FormControl className={classes.margin1RemTop} variant="outlined" fullWidth>
                    <InputLabel htmlFor="current-password-input">Current password</InputLabel>
                    <OutlinedInput
                        id="current-password-input"
                        value={currentPassword.value}
                        variant="outlined"
                        onChange={handleCurrentPasswordChange}
                        type={currentPassword.showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={handleCurrentPasswordClick}>
                                    {currentPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <ChangePasswordForm
                    newPassword={newPassword}
                    confirmNewPassword={confirmNewPassword}
                    onClickNewPassword={() =>
                        setNewPassword({ ...newPassword, showPassword: !newPassword.showPassword })
                    }
                    onClickConfirmNewPassword={() =>
                        setConfirmNewPassword({ ...confirmNewPassword, showPassword: !confirmNewPassword.showPassword })
                    }
                    onChangeNewPassword={event => setNewPassword({ ...newPassword, value: event.target.value })}
                    onChangeConfirmNewPassword={event =>
                        setConfirmNewPassword({ ...confirmNewPassword, value: event.target.value })
                    }
                />
                <Button className={[classes.button, classes.margin1RemTop]} onClick={handleOnClick}>
                    Change password
                </Button>
                {displayInfo.show && (
                    <Typography className={[classes.margin1RemTop, displayInfo.isRed && classes.fontRed]}>
                        {displayInfo.text}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}
