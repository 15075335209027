import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';
import DOMPurify from 'dompurify';

import { dialog } from './style';

const AlertDialog = ({ title, content, isVisible, setVisibility, actionOnContinue, actionOnCancel, customActionName }) => {
    const handleVisibility = option => setVisibility(option);

    const classes = makeStyles(theme => dialog(theme))();

    return (
        <Dialog
            open={isVisible}
            onClose={() => handleVisibility(false)}
            maxWidth={'xs'}
            className={classes.dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={classes.title} id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.description} id="alert-dialog-description">
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {actionOnCancel && (
                    <Button
                        onClick={() => {
                            actionOnCancel();
                            handleVisibility(false);
                        }}
                        className={classes.btn}
                        style={{ backgroundColor: 'red' }}
                    >
                        Back
                    </Button>
                )}
                <Button
                    onClick={() => {
                        actionOnContinue();
                        handleVisibility(false);
                    }}
                    className={classes.btn}
                    color="primary"
                    autoFocus
                >
                    {customActionName || 'Continue'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
