import { TABLET, MOBILE } from '../../constants/breakpoints';

export const forgotPasswordStyles = theme => ({
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        background: '#FFF',
        boxShadow: '10px 10px 16px -10px rgba(0,0,0,0.15)',
        padding: '0rem 1rem 1rem 1rem',
        width: '40%',
        fontSize: 14,

        [theme.breakpoints.down(TABLET)]: {
            width: '60%'
        },

        [theme.breakpoints.down(MOBILE)]: {
            width: '80%'
        }
    },
    margin1RemTop: {
        marginTop: '1rem'
    },
    button: {
        width: '100%',
        backgroundColor: theme.palette.blue.main,
        fontSize: 14,
        textTransform: 'none',
        color: '#fff',
        '&:hover': {
            color: '#fff',
            backgroundColor: theme.palette.blue.darken
        }
    },
    logoBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logo: {
        width: 130
    },
    textLogo: {
        marginTop: '1.2rem',
        width: 130
    },
    logoCaption: {
        marginTop: '0.5rem',
        marginBottom: '1.6rem',
        opacity: 0.8
    },
    info: {
        textAlign: 'center',
        fontSize: 12,
        marginTop: '1rem'
    },
    warning: {
        color: 'red'
    },
    title: {
        '& > h2': {
            display: 'flex',
            fontSize: '14px'
        }
    },
    buttonsContainer: {
        marginTop: '16px'
    }
});
