import { MOBILE, TABLET } from '../../constants/breakpoints';

export const thanksPageStyles = theme => ({
    thanksPageRoot: {
        width: '100%',
        padding: '0 1rem',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up(TABLET)]: {
            width: '100%'
        },
        [theme.breakpoints.down(MOBILE)]: {
            flexDirection: 'column'
        }
    },
    container: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '5%',
        [theme.breakpoints.up(MOBILE)]: {
            border: '1px solid #ccc',
            width: '48.5%',
            borderRadius: 10,
            marginBottom: 0
        }
    },
    thankYouTextStyles: {
        width: '100%'
    }
});

export const mapStyles = () => ({
    mapRoot: {
        height: '300px',
        width: '95%',
        margin: '2.5%'
    }
});
