import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getLocationItem } from '../../collums-constants/utils';
import * as Steps from '../../constants/steps';
import {
    getCurrentServices,
    getCurrentTime,
    getCurrentStep,
    getCurrentLocation,
    getCurrentStaff,
    getCurrentAppointments
} from '../../redux/selectors/book-appointment';
export const useAvailableSteps = () => {
    const currentStep = useSelector(getCurrentStep);
    const currentTime = useSelector(getCurrentTime);
    const currentStaff = useSelector(getCurrentStaff);
    const currentLocation = useSelector(getCurrentLocation);
    const currentServices = useSelector(getCurrentServices);

    // eslint-disable-next-line
    const availableSteps = [];

    useEffect(() => {
        if (currentLocation.name) availableSteps.push(Steps.LOCATION, Steps.SERVICE);
        if (currentServices !== null) availableSteps.push(Steps.STAFF);
        if (currentStaff !== null) availableSteps.push(Steps.TIME);
        if (currentTime) availableSteps.push(Steps.CONFIRM);
    }, [currentLocation, currentStaff, currentTime, currentServices, currentStep, availableSteps]);

    return availableSteps;
};

export const useServicesDuration = () => {
    const currentAppointments = useSelector(getCurrentAppointments);
    const currentLocation = useSelector(getCurrentLocation);
    const servicesDuration = useRef(0);
    useEffect(() => {
        if (currentAppointments.length !== 0) {
            servicesDuration.current = currentAppointments
                .map(appt => getLocationItem(appt.service, currentLocation.id)?.defaultDuration)
                .reduce((acc, current) => (acc = acc + current));
        } else {
            servicesDuration.current = 0;
        }
    }, [currentAppointments, currentLocation]);
    return servicesDuration;
};

export const useServicesPrice = () => {
    const currentAppointments = useSelector(getCurrentAppointments);
    const servicesPrice = useRef(0);
    useEffect(() => {
        if (currentAppointments.length !== 0) {
            servicesPrice.current = currentAppointments
                .map(appt => appt.service.defaultNetPrice)
                .reduce((acc, current) => (acc = acc + current));
        } else {
            servicesPrice.current = 0;
        }
    }, [currentAppointments]);
    return servicesPrice;
};
