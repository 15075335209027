import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import bookAppointment from './book-appointment';
import creditCard from './credit-card';
import auth from './auth';

export default combineReducers({
    toastr,
    bookAppointment,
    creditCard,
    auth
});
