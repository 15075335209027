import React from 'react';

import './styles.css';

function Marker() {
    return (
        <>
            <div className="pin" />
            <div className="pulse" />
        </>
    );
}

export default Marker;
