export const appointmentTable = theme => ({
    container: {
        border: '1px solid #ccc',
        padding: '1rem 1.5rem',
        borderRadius: '10px',
        '&::-webkit-scrollbar': {
            height: '4px !important',       
            zIndex: '1 !important'    
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px !important',
            width: '50%'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#ccc !important',
            borderRadius: '10px !important'
        }
    },
    table: {
        minWidth: 650,
        width: 'fit-contnt'    
    },
    row: {
        height: '3.5rem'
    },
    noContent: {
        height: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    popper: {
        background: theme.palette.gray.bold,
        color: 'white',
        border: 0,
        marginBottom: 7,
        marginTop: 0,
        padding: '4px 6px',
        fontSize: 12,
        borderRadius: 6,
        width: '60%',
        marginLeft: '20%',
        textAlign: 'center',
        zIndex: 0
    }
});
