import { isPossiblePhoneNumber } from 'libphonenumber-js';

export const isValidPhone = (phoneNumber, countryCode = 'uk', dialCode) => {
    if (phoneNumber.startsWith('+44 0')) {
        phoneNumber = phoneNumber.replace('+44 0', '+44 ');
    }
    const tempPhone = phoneNumber.replace(/\D/g, '');
    let valid = true;
    if (
        dialCode &&
        tempPhone.indexOf(dialCode) !== -1 &&
        tempPhone[tempPhone.indexOf(dialCode) + dialCode.length] === '0'
    )
        valid = false;
    if (phoneNumber.indexOf(' ') !== -1 && phoneNumber[phoneNumber.indexOf(' ') + 1] === '0') valid = false;
    if (phoneNumber.indexOf('(') !== -1 && phoneNumber[phoneNumber.indexOf('(') + 1] === '0') valid = false;
    try {
        return isPossiblePhoneNumber(phoneNumber, countryCode) && valid;
    } catch (err) {
        console.error(err);
    }
    return false;
};
