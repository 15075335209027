import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Typography, Button, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import BookAppointmentButton from './BookAppointmentButton';
import { changeModalVisibility } from '../../redux/actions/auth';
import { stepStyles } from '../book_appointment/styles';
import { useHistory, useLocation } from 'react-router-dom';

import { isAuthenticated, auth } from '../../redux/selectors/auth';
import { MOBILE, TABLET } from '../../constants/breakpoints';
import OrganisationApi from './../../api/organisationApi';
import { getLogoWidthAlternative } from './../../collums-components/helpers/index';
import { LOGO_HEIGHT } from '../../collums-constants';

export default function Header() {
    const [organisation, setOrganisation] = useState({});
    const [logoWidth, setLogoWidth] = useState(100);

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const isMobile = useMediaQuery(`(max-width:${MOBILE}px)`);
    const isTablet = useMediaQuery(`(max-width:${TABLET}px)`);

    const authenticated = useSelector(isAuthenticated);
    const showHomeBannerText = useSelector(state => state.auth.organisation.showHomeBannerText);
    const homeBannerText = useSelector(state => state.auth.organisation.homeBannerText);
    const userDetails = useSelector(auth);
    const classes = makeStyles(theme => stepStyles(theme))();

    const pathsToNotRender = ['/app', '/forgot-password'];
    const shouldRenderLoginButton = (() => {
        if (!authenticated && location.pathname !== '/forgot-password') return true;
        return false;
    })();

    const handleLoginButton = () => {
        dispatch(changeModalVisibility({ visible: true }));
    };

    useEffect(() => {
        OrganisationApi.getPublicOrganisation().then(res => {
            if (res.logo) {
                getLogoWidthAlternative(res.logo, LOGO_HEIGHT).then(setLogoWidth);
            }
            setOrganisation(res);
        });
    }, []);

    return (
        <Box display="flex" p={2} flexDirection="column" style={{ paddingBottom: '0' }}>
            {organisation.logo && (
                <Box style={{ width: logoWidth, height: 'auto', overflow: 'hidden' }}>
                    <img src={organisation.logo} alt="logo" style={{ width: logoWidth, height: 'auto' }} />
                </Box>
            )}
            <Typography className={classes.boldSubtitle}>{organisation.name} Online Booking</Typography>
            {showHomeBannerText && (
                <div className={classes.covidMessage} dangerouslySetInnerHTML={{ __html: homeBannerText }} />
            )}
            <div className={classes.headerWrapper}>
                {authenticated && <Typography className={classes.welcome}>Hi {userDetails.user.firstName}</Typography>}
                {authenticated && location.pathname !== '/' && (isMobile || isTablet) && <BookAppointmentButton />}
            </div>
            <div>
                {shouldRenderLoginButton && (
                    <Button
                        onClick={() => handleLoginButton()}
                        className={classes.widthFit}
                        style={{ marginRight: '1rem' }}
                        variant="contained"
                        color="primary"
                    >
                        Log in
                    </Button>
                )}
                {!pathsToNotRender.includes(location.pathname) && (
                    <Button
                        onClick={authenticated ? () => history.push('/app') : () => handleLoginButton()}
                        className={[classes.widthFit, isMobile ? '' : classes.aBtn].join(' ')}
                        variant="contained"
                        color="primary"
                    >
                        Manage appointments
                    </Button>
                )}
            </div>
        </Box>
    );
}
