import { MOBILE, TABLET } from '../../../constants/breakpoints';

export const passwordStyles = theme => ({
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    content: {
        width: '30%',
        [theme.breakpoints.down(TABLET)]: {
            width: '60%'
        },
        [theme.breakpoints.down(MOBILE)]: {
            width: '80%'
        }
    },
    margin1RemTop: {
        marginTop: '1rem'
    },
    button: {
        width: '100%',
        backgroundColor: theme.palette.blue.main,
        fontSize: 14,
        textTransform: 'none',
        color: '#fff',
        '&:hover': {
            color: '#fff',
            backgroundColor: theme.palette.blue.darken
        }
    },
    fontRed: {
        color: 'red'
    }
});
