import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Axios from 'axios';

import { passwordValidator } from '../../utils/helpers/index';
import {
    TITLES,
    GENDERS,
    PREFERRED_PHONE_COUNTRIES,
    REFERRAL_SOURCES_TYPE
} from '../../collums-constants/index';
import { complete_fields, existing_customer_email } from '../../constants/messages';
import { loginModalStyles } from './styles';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    makeStyles,
    TextField,
    Typography,
    CircularProgress,
    Checkbox
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { ArrowBackIos } from '@material-ui/icons';

import AuthApi from '../../api/authApi';

import { authUser, changeModalVisibility } from '../../redux/actions/auth';
import PhoneNumberInput from 'material-ui-phone-number';
import { isValidPhone } from '../../collums-components/helpers/validPhoneNumber';
import { getOrganisationCountryCode } from '../../collums-components/helpers';
import Moment from 'moment';
import ListApi from '../../collums-components/api/ListApi';

const titles = [TITLES.MRS, TITLES.MISS, TITLES.MS, TITLES.MR, TITLES.MX, TITLES.DR];
const genders = [GENDERS.MALE, GENDERS.FEMALE, GENDERS.TRANS_MALE, GENDERS.TRANS_FEMALE];
const signupMethodsConstant = {
    EXISTING_CUSTOMER: 'EXISTING_CUSTOMER',
    FIRST_TIME: 'FIRST_TIME'
};

const LoginModal = ({ setOpenInfoModal, setInfoText }) => {
    const modalVisibility = useSelector(state => state.auth.loginModalVisible);
    const organisation = useSelector(state => state.auth.organisation);

    const dispatch = useDispatch();
    const [form, setForm] = useState({});
    const [isLogin, setIsLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [accountCreated, setAccountCreated] = useState(false);
    const [referralSourceType, setReferralSourceType] = useState(Object.values(REFERRAL_SOURCES_TYPE).sort());
    const [displayInfo, setDisplayInfo] = useState({
        canDisplay: false,
        text: '',
        displayRed: false
    });

    const [isInvalid, setIsInvalid] = useState({
        otherPhone: false,
        phone: false
    });

    useEffect(() => {
        ListApi.getAll().then(lists => {
            const referralSource = lists.find(el => el.sys_name === 'client_referral_sources');
            if (referralSource) {
                setReferralSourceType(referralSource.values.split('\n'));
            }
        });
        //eslint-disable-next-line
    }, []);

    const [signupMethod, setSignupMethod] = useState(false);

    const currentStepState = useSelector(state => state.bookAppointment);
    const history = useHistory();

    const classes = makeStyles(loginModalStyles)();

    async function handleLogin() {
        setAccountCreated(false);
        if (isLogin) {
            setIsLoading(true);
            const authData = {
                email: (form.email || '').toLowerCase(),
                password: form.password
            };
            const response = await AuthApi.auth(authData);

            if (!response.user) {
                setDisplayInfo({
                    canDisplay: true,
                    text: response.message,
                    displayRed: true
                });
                setIsLoading(false);

                return;
            }

            const { token } = response;
            localStorage.setItem('token', token);
            Axios.defaults.headers.common['Authorization'] = token;
            await AuthApi.getMe()
                .then(user => {
                    dispatch(authUser(user));
                    dispatch(changeModalVisibility({ visible: false }));
                    setIsLoading(false);
                    if (currentStepState.currentStep === 'service') history.replace('/app');
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            setDisplayInfo({
                canDisplay: false,
                text: '',
                displayRed: false
            });
            setForm({});
            setIsLogin(true);
        }
    }

    function handleClose() {
        setDisplayInfo({
            canDisplay: false,
            text: '',
            displayRed: false
        });
        setForm({});
        setIsLogin(true);
        dispatch(changeModalVisibility({ visible: false }));
    }

    const getCountryCode = () => {
        return getOrganisationCountryCode({
            ...organisation,
            address: {
                country: organisation?.country || undefined
            }
        });
    };

    async function handleRegister() {
        setAccountCreated(false);
        if (!isLogin) {
            if (signupMethod === signupMethodsConstant.FIRST_TIME) {
                if (form.title && !titles.includes(form.title)) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: 'Invalid Title',
                        displayRed: true
                    });
                    return;
                }
                if (!form.name) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: 'Name is required',
                        displayRed: true
                    });
                    return;
                }

                if (!form.surname) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: 'Surname is required',
                        displayRed: true
                    });
                    return;
                }

                if (isInvalid.phone) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: 'Phone number is invalid',
                        displayRed: true
                    });
                    return;
                }

                if (!form.dob) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: 'Date of birth is required',
                        displayRed: true
                    });
                    return;
                }

                if (!genders.includes(form.gender)) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: 'Gender is required',
                        displayRed: true
                    });
                    return;
                }
                if (!form.mobile) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: 'Mobile is required',
                        displayRed: true
                    });
                    return;
                }

                if (!form.email) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: 'Email is required',
                        displayRed: true
                    });
                    return;
                }
            }

            if (signupMethod === signupMethodsConstant.EXISTING_CUSTOMER) {
                const response = await AuthApi.forgotPassword((form.email || '').toLowerCase(), true);

                if (response.statusCode === 400) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: response.message,
                        displayRed: true
                    });
                    setForm({});
                    return;
                }
                if (response) {
                    setDisplayInfo({
                        canDisplay: true,
                        text: existing_customer_email,
                        displayRed: false
                    });
                    setInfoText('sign_up_existing_customer_email');
                    dispatch(changeModalVisibility({ visible: false }));
                    setOpenInfoModal(true);
                    setForm({});
                }
                return;
            }


            if (!new RegExp(passwordValidator).test(form.password)) {
                setDisplayInfo({
                    canDisplay: true,
                    text: 'Password must have at least 8 characters, one capital letter and one number',
                    displayRed: true
                });
                return;
            }

            if (form.password !== form.confirmPassword) {
                setDisplayInfo({
                    canDisplay: true,
                    text: 'Passwords do not match',
                    displayRed: true
                });
                return;
            }

            const response = await AuthApi.register({
                title: form.title,
                name: (form.name || '').trim(),
                surname: (form.surname || '').trim(),
                dob: form.dob,
                gender: form.gender,
                email: (form.email || '').toLowerCase(),
                mobile: form.mobile,
                password: form.password,
                referralSource: form.referralSource
            });
            if (response.statusCode === 400) {
                setDisplayInfo({
                    canDisplay: true,
                    text: response.message,
                    displayRed: true
                });
                return;
            }
            setDisplayInfo({
                canDisplay: false,
                text: '',
                displayRed: false
            });
            setInfoText('sign_up_new_customer_email');
            dispatch(changeModalVisibility({ visible: false }));
            setOpenInfoModal(true);
            setAccountCreated(true);
            setForm({});
            setIsLogin(true);
            setSignupMethod(undefined);
        } else {
            setDisplayInfo({
                canDisplay: false,
                text: '',
                displayRed: false
            });
            setForm({});
            setIsLogin(false);
            setSignupMethod('sing up');
        }
    }

    const signupForm = () => {
        if (!signupMethod) return checkboxes();
        const title = (() => {
            if (signupMethod === signupMethodsConstant.EXISTING_CUSTOMER) {
                return <>
                    Enter the email address that we use to contact you and click “Sign Up" <br/>
                    The system will look for a match and, if found, connect you to your account.<br/>
                    If your email is not matched in our system, you will not receive an email.
                </>;
            } else if (signupMethod === signupMethodsConstant.FIRST_TIME) {
                return complete_fields;
            } else return '';
        })();

        return (
            <div style={{ marginBottom: '25px' }}>
                {checkboxes()}
                <Typography className={classes.singupTitle}>
                    <br />
                    {title}
                </Typography>
                {signupMethod === signupMethodsConstant.EXISTING_CUSTOMER && (
                    <TextField
                        value={form.email || ''}
                        onChange={event => setForm({ ...form, email: event.target.value })}
                        fullWidth
                        className={classes.inputMargin}
                        variant="outlined"
                        label="Email"
                        type="email"
                    />
                )}
                {signupMethod === signupMethodsConstant.FIRST_TIME && (
                    <>
                        <Autocomplete
                            options={titles}
                            inputValue={form.title || ''}
                            onInputChange={(event, value) => setForm({ ...form, title: value })}
                            className={classes.inputMargin}
                            renderOption={option => <Typography>{option}</Typography>}
                            autoHighlight
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    className={`${classes.autoComplete} ${classes.input}`}
                                    label="Title"
                                    variant="outlined"
                                />
                            )}
                        />
                        <TextField
                            required
                            value={form.name || ''}
                            onChange={event => setForm({ ...form, name: event.target.value })}
                            className={`${classes.inputMargin} ${classes.input}`}
                            fullWidth
                            variant="outlined"
                            label="Name"
                            type="text"
                        />
                        <TextField
                            value={form.surname || ''}
                            onChange={event => setForm({ ...form, surname: event.target.value })}
                            className={`${classes.inputMargin} ${classes.input}`}
                            fullWidth
                            variant="outlined"
                            label="Surname"
                            type="text"
                            required
                        />
                        <KeyboardDatePicker
                            inputVariant="outlined"
                            className={`${classes.inputMargin} ${classes.input}`}
                            fullWidth
                            required
                            value={form.dob || null}
                            label="Dob"
                            format="dd/MM/yyyy"
                            placeholder="dd/mm/yyyy"
                            onChange={event => {
                                if (!event) return;
                                const date = Moment(event).format('YYYY-MM-DD') + 'T12:00:00.00Z';
                                setForm({ ...form, dob: date });
                            }}
                        />
                        <Autocomplete
                            options={genders}
                            className={classes.inputMargin}
                            defaultValue={form.gender || ''}
                            onInputChange={(event, value) => setForm({ ...form, gender: value })}
                            renderOption={option => <Typography>{option}</Typography>}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    required
                                    className={`${classes.autoComplete} ${classes.input}`}
                                    label="Gender"
                                    variant="outlined"
                                />
                            )}
                        />

                        <PhoneNumberInput
                            fullWidth
                            required
                            variant="outlined"
                            value={form.mobile || ''}
                            label="Mobile"
                            enableLongNumbers={true}
                            inputClass={`${classes.inputMargin} ${classes.input}`}
                            onChange={(mobile, phoneInfo) => {
                                setIsInvalid({
                                    ...isInvalid,
                                    phone: !isValidPhone(mobile, phoneInfo.countryCode, phoneInfo.dialCode)
                                });
                                setForm({ ...form, mobile });
                            }}
                            error={isInvalid.phone}
                            preferredCountries={PREFERRED_PHONE_COUNTRIES}
                            defaultCountry={getCountryCode()}
                        />

                        <TextField
                            required
                            value={form.email || ''}
                            onChange={event => setForm({ ...form, email: event.target.value })}
                            fullWidth
                            className={`${classes.inputMargin} ${classes.input}`}
                            variant="outlined"
                            label="Email"
                            type="email"
                        />

                        <FormControl fullWidth>
                            <TextField
                                required
                                value={form.password || ''}
                                onChange={event => setForm({ ...form, password: event.target.value })}
                                className={`${classes.inputMargin} ${classes.input}`}
                                fullWidth
                                error={form.password && !new RegExp(passwordValidator).test(form.password)}
                                variant="outlined"
                                label="Password"
                                type="password"
                            />
                            {form.password && !new RegExp(passwordValidator).test(form.password) && (
                                <FormHelperText id="component-error-text">
                                    Password must have at least 8 characters, one capital letter and one number.
                                </FormHelperText>
                            )}
                        </FormControl>
                        <TextField
                            required
                            value={form.confirmPassword}
                            onChange={event => setForm({ ...form, confirmPassword: event.target.value })}
                            className={`${classes.inputMargin} ${classes.input}`}
                            fullWidth
                            variant="outlined"
                            label="Confirm Password"
                            type="password"
                        />
                        <FormControl fullWidth>
                            <Autocomplete
                                ListboxProps={{ id: 'listbox' }}
                                getOptionLabel={option => option && option}
                                options={referralSourceType}
                                autoHighlight
                                inputValue={form.referralSource}
                                onInputChange={event => setForm({ ...form, referralSource: event.target?.textContent })}
                                renderOption={option => <Typography>{option}</Typography>}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="How did you hear about us?"
                                        variant="outlined"
                                        className={`${classes.autoComplete} ${classes.inputMargin} ${classes.input} ${classes.textFieldReferralSource}`}
                                    />
                                )}
                            />
                        </FormControl>
                    </>
                )}
            </div>
        );
    };

    const checkboxes = () => {
        return (
            <div>
                <Box display={'flex'} alignItems="center">
                    <Checkbox
                        checked={signupMethod === signupMethodsConstant.FIRST_TIME}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onClick={() => setSignupMethod(signupMethodsConstant.FIRST_TIME)}
                    />
                    <Typography>I am new to {organisation.name} (creates a new client account)</Typography>
                </Box>
                <Box display={'flex'} alignItems="center">
                    <Checkbox
                        checked={signupMethod === signupMethodsConstant.EXISTING_CUSTOMER}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onClick={() => setSignupMethod(signupMethodsConstant.EXISTING_CUSTOMER)}
                    />
                    <Typography>I am an existing {organisation.name} customer</Typography>
                </Box>
            </div>
        );
    };

    const loginForm = () => {
        return (
            <>
                <TextField
                    value={form.email || ''}
                    onChange={event => setForm({ ...form, email: event.target.value })}
                    fullWidth
                    className={classes.inputMargin}
                    variant="outlined"
                    label="Email"
                    type="email"
                />

                <FormControl fullWidth>
                    <TextField
                        value={form.password || ''}
                        onChange={event => setForm({ ...form, password: event.target.value })}
                        className={classes.inputMargin}
                        fullWidth
                        variant="outlined"
                        label="Password"
                        type="password"
                    />
                </FormControl>

                <Box mt={2}>
                    <a href="/forgot-password" target="_blank" className={classes.linkColor}>
                        Forgot Password
                    </a>
                </Box>
            </>
        );
    };

    return (
        <Dialog
            open={!!modalVisibility.visible}
            className={classes.container}
            disableBackdropClick={currentStepState.currentStep === 'confirm' || modalVisibility.blockClose}
            onClose={handleClose}
            disableEscapeKeyDown={currentStepState.currentStep === 'confirm'}
        >
            {isLogin && accountCreated && (
                <Typography className={classes.accountCreated}>
                    Your account has been created. Please check your inbox (and junk mail) to verify your email before
                    logging in.
                </Typography>
            )}
            <DialogTitle className={classes.title}>
                {!isLogin && (
                    <div
                        className={classes.backButton}
                        onClick={() => {
                            setIsLogin(true);
                            setSignupMethod(undefined);
                        }}
                    >
                        <ArrowBackIos/>
                        Back
                    </div>
                )}
                {isLogin && 'Log in / sign up to continue'}
            </DialogTitle>
            <DialogContent>
                {!isLogin && signupForm()}
                {isLogin && loginForm()}

                {displayInfo.canDisplay && (
                    <Box className={classes.infoBox}>
                        <Typography className={[classes.info, displayInfo.displayRed && classes.fontRed]}>
                            {displayInfo.text}
                        </Typography>
                    </Box>
                )}
                <Box display="flex" justifyContent={!signupMethod ? 'space-between' : 'center'}
                    className={classes.buttonsContainer}>
                    {!signupMethod && (
                        <Button
                            disabled={isLoading}
                            onClick={handleLogin}
                            className={[classes.button, classes.blueButton]}
                        >
                            {isLoading ? 'Loading...' : 'Log In'}
                        </Button>
                    )}
                    <Button onClick={handleRegister} className={[classes.button, classes.pinkButton]}>
                        {isLoading ? 'Loading...' : 'Sign Up'}
                    </Button>
                </Box>
                {isLoading && <CircularProgress/>}
            </DialogContent>
        </Dialog>
    );
};

export default LoginModal;
