import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { selectorRowStyle } from './styles';

const Row = ({ children, onClick }) => {
    const classes = makeStyles(selectorRowStyle)();

    return (
        <Box className={classes.root} onClick={onClick}>
            {children}
        </Box>
    );
};

export default Row;
